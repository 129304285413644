//Auth paths
export const LOGIN_URL = 'login'
export const PORTAL = 'portal'
export const DASHBOARD_URL = "dashboard"
export const USER_MANAGEMENT_URL = "user-management"
export const SHARING_REPORTS = "sharing-reports"
export const BET_LIST_URL = "bet-list"
export const MY_ACCOUNT_STATEMENT_URL = "my-account-statement"
export const ADMIN_ACCOUNT_STATEMENT_URL = "admin-account-statement"
export const USER_ACCOUNT_STATEMENT_URL = "user-account-statement"
export const PROFIT_AND_LOSS_STATEMENT_URL = "profit-and-loss-statement"
export const GAME_REPORT_URL = "game-report"
export const ALERT_BETS_URL = "alert-bets"
export const COMMISSION_REPORT_URL = "commission-report"
export const QR_DETAILS_URL = "qr-details"
export const BANK_DETAILS_URL = "bank-details"
export const UPI_DETAILS_URL = "upi-details"
export const CRYPTO_DETAILS_URL = "crypto-details"
export const CRYPTO_VIEW_PAYMENTS = "crypto-payments"
export const ADMIN_VIEW_PAYMENTS = "view-payments"
export const ADMIN_VIEW_BONUS = "view-bonus"
export const ADMIN_SETTING = "settings"
export const CASINO_REPORT_URL = "casino-report"
export const DISPUTED_BETS_URL = "market-analysis"
export const USER_DETAIL_PAGE = "user"
export const MARKET_PAGE = "market"
export const MARKET_COMMISSION = "markt-categories"
export const MARKET_ADD_PAGE = "add-more"
export const MARKET_COMMISSION_ADD_PAGE = "commission-add-more"
export const BINARY_BET_HISTORY_PAGE = "binary-bet-history"
export const DERBY_REPORTS = "derby-reports"









