import React, { useEffect, useState } from "react";
import { sportsGetAPIAuth } from "../../sportsServices";

const MatchScore = ({ eventId, selectedGameId }) => {
  // console.log("selectedGameIdselectedGameId", selectedGameId);
  const [matchScoreData, setMatchScoreData] = useState([]);

  const getMatchScore = async () => {
    try {
      const res = await sportsGetAPIAuth(
        `bet/getScore?eventTypeId=${selectedGameId}&eventId=${eventId}`
      );
      if (res?.data?.status === 200) {
        setMatchScoreData(res?.data?.data?.data?.data);
        //   setMatchScoreData({
        //     teams:[ {
        //       team_short_name:"ABC",
        //       score:100,
        //   } , {
        //     team_short_name:"XYZ",
        //     score:110,
        //   } ],
        //   current_inning:"ABC",
        //   last24ballsNew:[
        //     {
        //       score_card:"100",
        //     },
        //     {
        //       score_card:"20",
        //     }
        //   ],
        //   currentRunRate:"3",
        //   msg:"msg"
        // });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  console.log("ssss", { matchScoreData, eventId, selectedGameId });

  useEffect(() => {
    getMatchScore();
    const interval = setInterval(() => {
      getMatchScore();
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, [selectedGameId, eventId]); //eslint-disable-line

  return (
    <>
      {/* -----------------------------------------------------Tennis */}
      {selectedGameId === "2" ? (
        matchScoreData?.length ? (
          <div className="matchScoreBox">
            <div className="row align-items-center teamStats">
              <div className="col-sm-6">
                <div className="row">
                  <div className="text-center">
                    <span>Set : </span>{" "}
                    <span>{matchScoreData?.[0]?.currentSet} </span>
                    <span> Game : </span>{" "}
                    <span>{matchScoreData?.[0].currentGame}</span>
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div
                  className="p-2  justify-content-between pb-2 pt-2 d-flex"
                  style={{
                    background: "white",
                    color: "black",
                    marginBottom: "2px",
                  }}
                >
                  <div style={{ color: matchScoreData?.[0]?.score?.home?.isServing ? "green" : "black" }} >{matchScoreData?.[0]?.score?.home?.name}</div>
                  <div>
                    {matchScoreData?.[0]?.score?.home?.gameSequence?.[0]}
                  </div>
                  <div>{matchScoreData?.[0]?.score?.home?.games}</div>
                  <div>{matchScoreData?.[0]?.score?.home?.score}</div>
                </div>

                <div
                  className="p-2  justify-content-between pb-2 pt-2 d-flex"
                  style={{
                    background: "white",
                    color: "black",
                    marginBottom: "2px",
                  }}
                >
                  <div style={{ color: matchScoreData?.[0]?.score?.away?.isServing ? "green" : "black" }} >{matchScoreData?.[0]?.score?.away?.name}</div>
                  <div>
                    {matchScoreData?.[0]?.score?.away?.gameSequence?.[0]}
                  </div>
                  <div>{matchScoreData?.[0]?.score?.away?.games}</div>
                  <div>{matchScoreData?.[0]?.score?.away?.score}</div>
                </div>

              </div>
            </div>
          </div>
        ) : (
          ""
        )
      ) : ""}

      {/* ----------------------------------------------------- football */}
      {selectedGameId === "1" ? (
        matchScoreData?.length ? (
          <div className="matchScoreBox">
            <div className="row align-items-center teamStats">
              <div className="col-sm-6">
                <div className="row">
                  <div className="text-center">
                    <span>{matchScoreData?.[0]?.timeElapsed} Set :- </span>{" "}
                    <span>{matchScoreData?.[0]?.currentSet} </span>
                    <span> Game :- </span>{" "}
                    <span> Points </span>
                    {/* <span>{matchScoreData?.[0].currentGame}</span> */}
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div
                  className="p-2  justify-content-between pb-2 pt-2 d-flex"
                  style={{
                    background: "white",
                    color: "black",
                    marginBottom: "2px",
                  }}
                >
                  <div style={{ color: matchScoreData?.[0]?.score?.home?.isServing ? "green" : "black" }} >{matchScoreData?.[0]?.score?.home?.name}</div>
                  <div>{matchScoreData?.[0]?.score?.home?.score}</div>
                </div>

                <div
                  className="p-2  justify-content-between pb-2 pt-2 d-flex"
                  style={{
                    background: "white",
                    color: "black",
                    marginBottom: "2px",
                  }}
                >
                  <div style={{ color: matchScoreData?.[0]?.score?.away?.isServing ? "green" : "black" }} >{matchScoreData?.[0]?.score?.away?.name}</div>
                  <div>{matchScoreData?.[0]?.score?.away?.score}</div>
                </div>

              </div>
            </div>
          </div>
        ) : (
          ""
        )
      ) : ""}

      {selectedGameId === "4" ?
        matchScoreData?.teams?.length ? (
          <div className="matchScoreBox">
            {matchScoreData?.teams?.map((item) => (
              <>
                <div className="row align-items-center teamStats">
                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col-md">{item?.team_short_name}</div>
                      <div className="col-md-auto">
                        {item?.score}

                        {item?.team_short_name ===
                          matchScoreData?.current_inning ? (
                          <span className="ps-2">
                            CRR {matchScoreData?.currentRunRate}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    {item?.team_short_name === matchScoreData?.current_inning ? (
                      <div className="text-sm-end">{matchScoreData?.msg}</div>
                    ) : (
                      <>
                        <div className="oversData d-flex justify-content-sm-end mt-1 mt-sm-0">
                          {matchScoreData?.last24ballsNew?.map((ele) => (
                            <div
                              className={`over ${ele?.score_card === "ww" ? "wicket" : ""
                                }`}
                            >
                              {ele?.score_card}
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            ))}
          </div>
        ) : (
          ""
        ) : ""}
    </>
  );
};

export default MatchScore;
