import React, { useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { ROUTES_CONST } from "../../constants/routeConstant";
import DownloadModal from "./../../pages/DownloadApp/DownloadModal";
import { useEffect } from "react";
import { AUTH_MODAL_TABS, WALLET_MODAL_TABS } from "../../constants";
import { useWalletModal } from "../../hooks/useWallet";
import { useAuth } from "../../hooks/useAuth";
import { useAuthModal } from "../../hooks/useAuthModal";
import UnAuthCurrencyModal from "../UnAuthCurrencyModal/UnAuthCurrencyModal";
import { useDispatch, useSelector } from "react-redux";
import { useDownloadModal } from "../../hooks/useDownload";
import { useTranslation } from "react-i18next";
import { sportsGetAPI } from "../../sports/sportsServices";
import { getBetGames, getSubscriptionUsers, setGameId } from "../../store/action";
import { errorToaster } from "../../utils/toaster";
import { useFantasyLoader } from "../../hooks/useFantasyLoader";
import { getAPI, getAPIAuth } from "../../service/apiInstance";
import './style.css'

const AppSideBar = () => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const { setWalletModalObject } = useWalletModal();
  const { setShowFantasyLoader } = useFantasyLoader();
  const { setAuthModalObject } = useAuthModal();
  const [showLanguage, setShowLanguage] = useState(false);
  const [packagesubscription, setPackageSubscription] = useState([]);
  const [selectedTab, setSelectedTab] = useState();
  const { setDownloadObject } = useDownloadModal();
  const location = useLocation().pathname;
  const { t } = useTranslation();
  const sidebarLabels = t("appSideApp", { returnObjects: true });
  const matchId = useSelector((state) => state.matchId);
  const subscribeUser = useSelector((state) => state?.getSubscribeUser);
  console.log("res?.data?.data?.data?.[0]", subscribeUser?.[0]?.status);
  const [isOpen, setIsOpen] = useState(() => {
    const exists = document.body.classList;
    if (exists["value"] === "asideBar") {
      // document.body.classList.remove("asideBar");
      return true;
    } else {
      // document.body.classList.add("asideBar");
      return false;
    }
  });

  const [series, setSeries] = useState();
  // console.log("series", series);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const[permissions, setPermissions]= useState();
  const userFullDetails = useSelector((state) => state?.userFullDetails);
  const subscribeDataToken = subscribeUser?.[0]?.status || subscribeUser?.status
  useEffect(() => {
    const exists = document.body.classList.contains("asideBar");
    if (exists) {
      document.body.classList.remove("asideBar");
    }
  }, [location]);

  const getMatchSeries = async () => {
    try {
      const res = await sportsGetAPI("bet/games");
      if (res?.data?.data?.success) {
        setSeries(res?.data?.data?.data);
        dispatch(getBetGames(res?.data?.data?.data));
        // setSelectedCat(res.data.data.data[0])
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getMatchSeries();
  }, []);

  const getUserPackageSubscription = async () => {

    if (token) {
      try {
        const res = await getAPIAuth("user/getUserPackageSubscription", token);
        console.log("res", res);
        if (res?.data?.data?.success) {
          console.log("res?.data?.data?.data ", res?.data?.data?.data);
          dispatch(getSubscriptionUsers(res?.data?.data?.data));
          setPackageSubscription(res?.data?.data?.data);
        }
      } catch (error) {
        console.log("error", error);
      }
    }

  }
  const getPermission = async () => {

      try {
        const res = await getAPI("user/getMenuSettings");
        if (res?.data?.data?.success) {
          // console.log("res?.data?.data?.data ", res?.data.data.response);
        setPermissions(res?.data.data.response);
        }
      } catch (error) {
        console.log("error", error);
      }

  }

  useEffect(() => {
    getPermission();
  }, []);

  useEffect(() => {
    getUserPackageSubscription();
  }, [token, subscribeDataToken]);

  const sideAppHandler = () => {
    const exists = document.body.classList.contains("asideBar");
    if (exists) {
      document.body.classList.remove("asideBar");
    } else {
      document.body.classList.add("asideBar");
    }
  };

  const handleSidebar = () => {
    document.body.classList.toggle("shortSidebar");
  };

  useEffect(() => {
    const exists = document.body.classList;

    if (isOpen) {
      document.body.classList.add("asideBar");
    } else {
      document.body.classList.remove("asideBar");
    }
  }, [isOpen]);

  // function for opening deposit modal
  const handleDepositClick = () => {
    if (token) {
      setWalletModalObject((prev) => ({
        isWalletOpen: true,
        selectedTab: WALLET_MODAL_TABS.DEPOSIT,
      }));
    } else {
      setAuthModalObject((pre) => ({
        isAuthOpen: true,
        selectedTab: AUTH_MODAL_TABS.LOG_IN,
      }));
    }
  };
// console.log(permissions)

const handlePermision = (name) => {
  if (permissions && permissions[name]) {
    return true;
  }
  return false;
};
  return (
    <>
      <aside className="sidebar position-fixed">
        <div className="row mx-0 sidebar-menu flex-column flex-nowrap h-100 ">
          <div className="col-12 px-0">
            <div className="d-flex align-items-center sidebarHeading justify-content-between">
              <div className="menuHeading shortBarHideContent text-nowrap">
                {sidebarLabels.MAIN_MENU}
              </div>
              <button
                onClick={handleSidebar}
                className="sidebarBtn d-xl-block d-none bg-transparent border-0 p-0 d-flex align-items-center justify-content-center"
              >
                <img
                  src="assets/img/sidebar/appIcon.png"
                  alt="side bar"
                  className="h-100 w-100 img-fluid d-flex object-fit-contain"
                />
              </button>
              <button
                onClick={() => {
                  sideAppHandler();
                }}
                className="sidebarBtn d-xl-none d-block toggleButton bg-transparent border-0 p-0 d-flex align-items-center justify-content-center"
              >
                <img
                  src="assets/img/Closeicon.png"
                  className="w-100 h-100 w-100 img-fluid d-flex object-fit-contain"
                  alt=""
                />
              </button>
            </div>
          </div>

          <div className="col-12 overflow-y-auto flex-fill sidebarData d-flex flex-column">
            <div className="sideBarBody flex-fill">
              <div className="row">
                {handlePermision("home") && 
                <div className="col-12">
                  <NavLink
                    to={ROUTES_CONST.INDEX}
                    className="d-flex text-decoration-none align-items-center livesupport cursor-pointer"
                  >
                    <span className="sponsorshipIcon d-flex align-items-center justify-content-center">
                      <img
                        src="assets/img/sidebar/new/homeIcon.png"
                        alt="Sponsorship"
                        className=" w-100"
                      />
                    </span>
                    <span className="shortBarHideContent">Home</span>
                  </NavLink>
                </div>
                }
                {handlePermision("subscription") && 
                <div className="col-12">
                  <NavLink
                    to={ROUTES_CONST.SUBSCRIPTION_PAGE}
                    className="d-flex text-decoration-none align-items-center livesupport cursor-pointer"
                  >
                    <span className="sponsorshipIcon d-flex align-items-center justify-content-center">
                      <img
                        src="assets/img/sidebar/new/subscription.png"
                        alt="Sponsorship"
                        className=" w-100"
                      />
                    </span>
                    <span className="shortBarHideContent">
                      {sidebarLabels.SUBSCRIPTION}
                    </span>
                  </NavLink>

                </div>
                  }
                   {handlePermision("subscription") && 
                <div className="col-12">
                  <NavLink
                    to={ROUTES_CONST.SUBSCRIPTION_DETAIL}
                    className="d-flex text-decoration-none align-items-center livesupport cursor-pointer"
                  >
                    <span className="sponsorshipIcon d-flex align-items-center justify-content-center">
                      <img
                        src="assets/img/sidebar/new/subscription.png"
                        alt="Sponsorship"
                        className=" w-100"
                      />
                    </span>
                    <span className="shortBarHideContent">
                      {sidebarLabels.SUBSCRIPTION_DETAILS}
                    </span>
                  </NavLink>

                </div>
                  }
                <div className="accordion " id="accordionExample">
                  <div className="accordion-item col-12 bg-transparent shadow-none border-0">

                    {
                      subscribeDataToken == 'active' ? (
                        <div className="accordion-header ">
                          <button
                            className="accordion-button d-flex align-items-center sideMenuBtn shadow-none bg-transparent p-0 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#subscription"
                            // aria-expanded="true"
                            aria-expanded="false"
                            aria-controls="subscription"
                          >
                            <span
                              onClick={() => navigate(ROUTES_CONST.SPORTS_SUBSCRIPTION)}
                              className="accordionIcon d-flex align-items-center justify-content-center"
                            >
                              <img
                                src="assets/img/sidebar/new/subscription.png"
                                alt="sports"
                                className="h-100 w100 img-fluid"
                              />
                            </span>
                            <span
                              onClick={() => navigate(ROUTES_CONST.SPORTS_SUBSCRIPTION)}
                              className="shortBarHideContent"
                            >
                              {/* {sidebarLabels.Sports} */}
                              Sports Subscription
                            </span>
                          </button>


                        </div>
                      ) : <div></div>
                    }
                    <div
                      id="subscription"
                      // className="accordion-collapse sidebarContent collapse show"
                      className="accordion-collapse sidebarContent collapse "
                    >
                      <div className="accordion-body active-subscribtion-color p-0">
                        <NavLink
                          to={ROUTES_CONST.SUBSCRIBTION_MY_BET_PAGE}
                          className="d-flex text-decoration-none align-items-center sideMenuContent cursor-pointer"
                        >
                          <span className="d-flex align-items-center sidebtnImg justify-content-center ">
                            <img
                              src="assets/img/sidebar/new/myBets.png"
                              alt="mybets"
                              className="w-100"
                            />
                          </span>
                          <span className="shortBarHideContent">
                            {sidebarLabels.MyBets}
                          </span>
                        </NavLink>
                        {!isLoading ? (
                          <>
                            {series?.length > 0 ? (
                              <>
                                {series?.map((item) => (
                                  <span
                                    onClick={() => {
                                      dispatch(setGameId({ "gameid": item?.gameId, "image": item?.image }));
                                      navigate(ROUTES_CONST.SPORTS_SUBSCRIPTION);
                                    }}
                                    key={item?._id}
                                    className={`d-flex text-decoration-none align-items-center sideMenuContent cursor-pointer ${location === ROUTES_CONST.SPORTS &&
                                      item?.gameId === matchId
                                      ? "active"
                                      : ""
                                      }`}
                                  >
                                    <span className="d-flex align-items-center sidebtnImg justify-content-center ">
                                      <img
                                        src={item?.image}
                                        alt="mybets"
                                        className="w-100"
                                      />
                                    </span>
                                    <span className="shortBarHideContent">
                                      {item?.name}
                                    </span>
                                  </span>
                                ))}

                                <NavLink
                                  to={ROUTES_CONST.SUBSCRIBTION_BET_LIST}
                                  className="d-flex text-decoration-none align-items-center livesupport cursor-pointer"
                                >
                                  <span className="sponsorshipIcon d-flex align-items-center justify-content-center">
                                    <img
                                      src="assets/img/sidebar/new/myBets.png"
                                      alt="Sponsorship"
                                      className=" w-100"
                                    />
                                  </span>
                                  <span className="shortBarHideContent">
                                    {sidebarLabels.BET_LIST}
                                  </span>
                                </NavLink>
                                <NavLink
                                  to={ROUTES_CONST.MY_ACCOUNT_SUBSCRIBTION_STATEMENT}
                                  className="d-flex text-decoration-none align-items-center livesupport cursor-pointer"
                                >
                                  <span className="sponsorshipIcon d-flex align-items-center justify-content-center">
                                    <img
                                      src="assets/img/sidebar/new/myBets.png"
                                      alt="Sponsorship"
                                      className=" w-100"
                                    />
                                  </span>
                                  <span className="shortBarHideContent">
                                    {sidebarLabels.MY_ACCOUNT_STATEMENT}
                                  </span>
                                </NavLink>
                                <NavLink
                                  to={ROUTES_CONST.COMMISSION_SUBSCRIBTION_REPORT}
                                  className="d-flex text-decoration-none align-items-center livesupport cursor-pointer"
                                >
                                  <span className="sponsorshipIcon d-flex align-items-center justify-content-center">
                                    <img
                                      src="assets/img/sidebar/new/myBets.png"
                                      alt="Sponsorship"
                                      className=" w-100"
                                    />
                                  </span>
                                  <span className="shortBarHideContent">
                                    {sidebarLabels.COMMISSION_REPORT}
                                  </span>
                                </NavLink>
                              </>
                            ) : (
                              "no data"
                            )}
                          </>
                        ) : (
                          "loader"
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item col-12 bg-transparent shadow-none border-0">
                   {handlePermision("sports") && 
                    <div className="accordion-header">
                      <button
                        className="accordion-button d-flex align-items-center sideMenuBtn shadow-none bg-transparent p-0 collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#sports"
                        // aria-expanded="true"
                        aria-expanded="false"
                        aria-controls="sports"
                      >
                        <span
                          onClick={() => navigate(ROUTES_CONST.SPORTS)}
                          className="accordionIcon d-flex align-items-center justify-content-center"
                        >
                          <img
                            src="assets/img/sidebar/new/football.png"
                            alt="sports"
                            className="h-100 w100 img-fluid"
                          />
                        </span>
                        <span
                          onClick={() => navigate(ROUTES_CONST.SPORTS)}
                          className="shortBarHideContent"
                        >
                          {sidebarLabels.Sports}
                        </span>
                      </button>
                    </div>
                    }
                    <div
                      id="sports"
                      // className="accordion-collapse sidebarContent collapse show"
                      className="accordion-collapse sidebarContent collapse "
                    >
                      <div className="accordion-body p-0">
                        <NavLink
                          to={ROUTES_CONST.SPORTS_MY_BET_PAGE}
                          className="d-flex text-decoration-none align-items-center sideMenuContent cursor-pointer"
                        >
                          <span className="d-flex align-items-center sidebtnImg justify-content-center ">
                            <img
                              src="assets/img/sidebar/new/myBets.png"
                              alt="mybets"
                              className="w-100"
                            />
                          </span>
                          <span className="shortBarHideContent">
                            {sidebarLabels.MyBets}
                          </span>
                        </NavLink>
                        {!isLoading ? (
                          <>
                            {series?.length > 0 ? (
                              <>
                                {series?.map((item) => (
                                  <span
                                    onClick={() => {
                                      dispatch(setGameId({ "gameid": item?.gameId, "image": item?.image }));
                                      navigate(ROUTES_CONST.SPORTS);
                                    }}
                                    key={item?._id}
                                    className={`d-flex text-decoration-none align-items-center sideMenuContent cursor-pointer ${location === ROUTES_CONST.SPORTS &&
                                      item?.gameId === matchId
                                      ? "active"
                                      : ""
                                      }`}
                                  >
                                    <span className="d-flex align-items-center sidebtnImg justify-content-center ">
                                      <img
                                        src={item?.image}
                                        alt="mybets"
                                        className="w-100"
                                      />
                                    </span>
                                    <span className="shortBarHideContent">
                                      {item?.name}
                                    </span>
                                  </span>
                                ))}
                              </>
                            ) : (
                              "no data"
                            )}
                          </>
                        ) : (
                          "loader"
                        )}
                      </div>
                    </div>
                  </div>
                  {token ? (
                    <>
                      <div className="accordion-item col-12 bg-transparent shadow-none border-0">
                   {handlePermision("sportsReports") && 
                        <div className="accordion-header">
                          <button
                            className="accordion-button d-flex align-items-center sideMenuBtn shadow-none bg-transparent p-0 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#reports"
                            aria-expanded="false"
                            aria-controls="reports"
                          >
                            <span className="accordionIcon d-flex align-items-center justify-content-center">
                              <img
                                src="assets/img/sidebar/new/reports.png"
                                alt="sports"
                                className="h-100 w100 img-fluid"
                              />
                            </span>
                            <span className="shortBarHideContent">
                              {sidebarLabels.REPORTS}
                            </span>
                          </button>
                        </div>
                        }
                        <div
                          id="reports"
                          className="accordion-collapse sidebarContent collapse "
                        >
                          <div className="accordion-body p-0">
                            <div className="col-12">
                              <NavLink
                                to={ROUTES_CONST.BET_LIST}
                                className="d-flex text-decoration-none align-items-center livesupport cursor-pointer"
                              >
                                <span className="sponsorshipIcon d-flex align-items-center justify-content-center">
                                  <img
                                    src="assets/img/sidebar/new/myBets.png"
                                    alt="Sponsorship"
                                    className=" w-100"
                                  />
                                </span>
                                <span className="shortBarHideContent">
                                  {sidebarLabels.BET_LIST}
                                </span>
                              </NavLink>
                            </div>
                            <div className="col-12">
                              <NavLink
                                to={ROUTES_CONST.MY_ACCOUNT_STATEMENT}
                                className="d-flex text-decoration-none align-items-center livesupport cursor-pointer"
                              >
                                <span className="sponsorshipIcon d-flex align-items-center justify-content-center">
                                  <img
                                    src="assets/img/sidebar/new/myBets.png"
                                    alt="Sponsorship"
                                    className=" w-100"
                                  />
                                </span>
                                <span className="shortBarHideContent">
                                  {sidebarLabels.MY_ACCOUNT_STATEMENT}
                                </span>
                              </NavLink>
                            </div>
                            <div className="col-12">
                              <NavLink
                                to={ROUTES_CONST.COMMISSION_REPORT}
                                className="d-flex text-decoration-none align-items-center livesupport cursor-pointer"
                              >
                                <span className="sponsorshipIcon d-flex align-items-center justify-content-center">
                                  <img
                                    src="assets/img/sidebar/new/myBets.png"
                                    alt="Sponsorship"
                                    className=" w-100"
                                  />
                                </span>
                                <span className="shortBarHideContent">
                                  {sidebarLabels.COMMISSION_REPORT}
                                </span>
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item col-12 bg-transparent shadow-none border-0">
                   {handlePermision("trading") && 
                        <div className="accordion-header">
                          <button
                            className="accordion-button d-flex align-items-center sideMenuBtn shadow-none bg-transparent p-0 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#trading"
                            aria-expanded="false"
                            aria-controls="trading"
                          >
                            <span className="accordionIcon d-flex align-items-center justify-content-center">
                              <img
                                src="assets/img/sidebar/new/tradingIcon.png"
                                alt="sports"
                                className="h-100 w100 img-fluid"
                              />
                            </span>
                            <span className="shortBarHideContent">
                              {sidebarLabels.TRADING}
                            </span>
                          </button>
                        </div>
                        }
                        <div
                          id="trading"
                          className="accordion-collapse sidebarContent collapse "
                        >
                          <div className="accordion-body p-0">
                            <NavLink
                              to={ROUTES_CONST.TRADING_WATCHLIST}
                              className="d-flex text-decoration-none align-items-center sideMenuContent cursor-pointer"
                            >
                              <a
                                to=""
                                className="d-flex align-items-center sidebtnImg justify-content-center "
                              >
                                <img
                                  src="assets/img/sidebar/new/myBets.png"
                                  alt="mybets"
                                  className="w-100"
                                />
                              </a>
                              <span className="shortBarHideContent">
                                {sidebarLabels.WATCHLIST}
                              </span>
                            </NavLink>
                            <NavLink
                              to={ROUTES_CONST.TRADING_PORTFOLIO}
                              className="d-flex text-decoration-none align-items-center sideMenuContent cursor-pointer"
                            >
                              <a
                                to=""
                                className="d-flex align-items-center sidebtnImg justify-content-center "
                              >
                                <img
                                  src="assets/img/sidebar/new/myBets.png"
                                  alt="mybets"
                                  className="w-100"
                                />
                              </a>
                              <span className="shortBarHideContent">
                                {sidebarLabels.PORTFOLIO_POSITION}
                              </span>
                            </NavLink>
                            <NavLink
                              to={ROUTES_CONST.TRADING_HISTORY}
                              className="d-flex text-decoration-none align-items-center sideMenuContent cursor-pointer"
                            >
                              <a
                                to=""
                                className="d-flex align-items-center sidebtnImg justify-content-center "
                              >
                                <img
                                  src="assets/img/sidebar/new/myBets.png"
                                  alt="mybets"
                                  className="w-100"
                                />
                              </a>
                              <span className="shortBarHideContent">
                                {sidebarLabels.HISTORY}
                              </span>
                            </NavLink>
                            <NavLink
                              to={ROUTES_CONST.TRADING_ORDERS}
                              className="d-flex text-decoration-none align-items-center sideMenuContent cursor-pointer"
                            >
                              <a
                                to=""
                                className="d-flex align-items-center sidebtnImg justify-content-center "
                              >
                                <img
                                  src="assets/img/sidebar/new/myBets.png"
                                  alt="mybets"
                                  className="w-100"
                                />
                              </a>
                              <span className="shortBarHideContent">
                                {sidebarLabels.ORDERS}
                              </span>
                            </NavLink>
                            <NavLink
                              to={ROUTES_CONST.TRADING_Profit_Loss}
                              className="d-flex text-decoration-none align-items-center sideMenuContent cursor-pointer"
                            >
                              <a
                                to=""
                                className="d-flex align-items-center sidebtnImg justify-content-center "
                              >
                                <img
                                  src="assets/img/sidebar/new/myBets.png"
                                  alt="mybets"
                                  className="w-100"
                                />
                              </a>
                              <span className="shortBarHideContent">
                                {sidebarLabels.PROFIT_AND_LOSS}
                              </span>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                {handlePermision("jackpot") && 
                <div className="col-12">
                  <NavLink
                    to={ROUTES_CONST.JACKPOT}
                    className="d-flex text-decoration-none align-items-center livesupport cursor-pointer"
                  >
                    <span className="sponsorshipIcon d-flex align-items-center justify-content-center">
                      <img
                        src="assets/img/sidebar/new/subscription.png"
                        alt="Sponsorship"
                        className=" w-100"
                      />
                    </span>
                    <span className="shortBarHideContent">
                      {sidebarLabels.JACKPOT}
                    </span>
                  </NavLink>
                </div>
                }
                {/*  */}
                {handlePermision("fantasy") && 
                <div className="col-12">
                  <span
                    onClick={() => {
                      console.log("hello", userFullDetails);
                      if (!!Object.values(userFullDetails).length) {
                        if (!!userFullDetails.isCricket) {
                          setShowFantasyLoader(true);
                          navigate(ROUTES_CONST.FANTASY_HOME);
                          setTimeout(() => {
                            setShowFantasyLoader(false);
                          }, 3000);
                        } else {
                          // errorToaster("You are not authorized  ")
                          errorToaster(
                            "You don't have access to this section "
                          );
                        }
                      } else {
                        errorToaster("Not authorized to visit this section");
                      }
                    }}
                    className="d-flex text-decoration-none align-items-center livesupport cursor-pointer"
                  >
                    <span className="sponsorshipIcon d-flex align-items-center justify-content-center">
                      <img
                        src="assets/img/sidebar/new/newFantasyIcon.png"
                        alt="Sponsorship"
                        className=" w-100"
                      />
                    </span>
                    <span className="shortBarHideContent">
                      {sidebarLabels.FANTASY}
                    </span>
                  </span>
                </div>
                }
                {handlePermision("winMore") && 
                <div className="col-12">
                  <NavLink
                    to={ROUTES_CONST.WIN_MORE}
                    className="d-flex text-decoration-none align-items-center livesupport cursor-pointer"
                  >
                    <span className="sponsorshipIcon d-flex align-items-center justify-content-center">
                      <img
                        src="assets/img/sidebar/new/subscription.png"
                        alt="Sponsorship"
                        className=" w-100"
                      />
                    </span>
                    <span className="shortBarHideContent">
                      {sidebarLabels.WINMORE}
                    </span>
                  </NavLink>

                </div>
                }
                {handlePermision("promotions") && 
                <div className="col-12">
                  <NavLink
                    to={ROUTES_CONST.PROMOTIONS}
                    className="d-flex text-decoration-none align-items-center livesupport cursor-pointer"
                  >
                    <span className="sponsorshipIcon d-flex align-items-center justify-content-center">
                      <img
                        src="assets/img/sidebar/new/promotion.png"
                        alt="Sponsorship"
                        className=" w-100"
                      />
                    </span>
                    <span className="shortBarHideContent">
                      {sidebarLabels.PROMOTIONS}
                    </span>
                  </NavLink>
                </div>
                }
                {/* casino page */}
                {token ? (
                  <>
                {handlePermision("casino") && 
                    <div className="col-12">
                      <NavLink
                        to={ROUTES_CONST.CASINO_HOME}
                        className="d-flex text-decoration-none align-items-center livesupport cursor-pointer"
                      >
                        <span className="sponsorshipIcon d-flex align-items-center justify-content-center">
                          <img
                            src="assets/img/sidebar/new/casinoIcon.png"
                            alt="Sponsorship"
                            className=" w-100"
                          />
                        </span>
                        <span className="shortBarHideContent">Casino</span>
                      </NavLink>
                    </div>
                    }
                {handlePermision("mainWalletReport") && 
                    <div className="col-12">
                      <NavLink
                        to={ROUTES_CONST.WALLET}
                        className="d-flex text-decoration-none align-items-center livesupport cursor-pointer"
                      >
                        <span className="sponsorshipIcon d-flex align-items-center justify-content-center">
                          <img
                            src="assets/img/sidebar/new/casinoIcon.png"
                            alt="Sponsorship"
                            className=" w-100"
                          />
                        </span>
                        <span className="shortBarHideContent">Main Wallet Report</span>
                      </NavLink>
                    </div>
                    }
                    <div className="accordion-item col-12 bg-transparent shadow-none border-0">
                     {handlePermision("casinoReports") && 
                      <div className="accordion-header">
                        <button
                          className="accordion-button d-flex align-items-center sideMenuBtn shadow-none bg-transparent p-0 collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#casinoReports"
                          aria-expanded="false"
                          aria-controls="reports"
                        >
                          <span className="accordionIcon d-flex align-items-center justify-content-center">
                            <img
                              src="assets/img/sidebar/new/reports.png"
                              alt="sports"
                              className="h-100 w100 img-fluid"
                            />
                          </span>
                          <span className="shortBarHideContent">
                            Casino Reports
                          </span>
                        </button>
                      </div>
                      }
                      <div
                        id="casinoReports"
                        className="accordion-collapse sidebarContent collapse "
                      >
                        <div className="accordion-body p-0">
                          <div className="col-12">
                            <NavLink
                              to={ROUTES_CONST.CASINO_HISTORY}
                              className="d-flex text-decoration-none align-items-center livesupport cursor-pointer"
                            >
                              <span className="sponsorshipIcon d-flex align-items-center justify-content-center">
                                <img
                                  src="assets/img/sidebar/new/myBets.png"
                                  alt="Sponsorship"
                                  className=" w-100"
                                />
                              </span>
                              <span className="shortBarHideContent">
                                {sidebarLabels.BET_LIST}
                              </span>
                            </NavLink>
                          </div>
                          <div className="col-12">
                            <NavLink
                              to={ROUTES_CONST.MY_ACCOUNT_CASINO_STATEMENT}
                              className="d-flex text-decoration-none align-items-center livesupport cursor-pointer"
                            >
                              <span className="sponsorshipIcon d-flex align-items-center justify-content-center">
                                <img
                                  src="assets/img/sidebar/new/myBets.png"
                                  alt="Sponsorship"
                                  className=" w-100"
                                />
                              </span>
                              <span className="shortBarHideContent">
                                {sidebarLabels.MY_ACCOUNT_STATEMENT}
                              </span>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                     {handlePermision("plReports") && 
                    <div className="col-12">
                      <NavLink
                        to={ROUTES_CONST.CASINO_REPORTS}
                        className="d-flex text-decoration-none align-items-center livesupport cursor-pointer"
                      >
                        <span className="sponsorshipIcon d-flex align-items-center justify-content-center">
                          <img
                            src="assets/img/sidebar/new/myBets.png"
                            alt="Sponsorship"
                            className=" w-100"
                          />
                        </span>
                        <span className="shortBarHideContent">P/L Reports</span>
                      </NavLink>
                    </div>
                    }
                  </>
                ) : (
                  <></>
                )}
               {handlePermision("downloadApp") && 
                <div className="col-12 d-none d-lg-block">
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      setDownloadObject({
                        isDownloadOpen: true,
                      })
                    }
                  >
                    <div className="d-flex text-decoration-none align-items-center livesupport cursor-pointer">
                      <span className="sponsorshipIcon d-flex align-items-center justify-content-center">
                        <img
                          src="assets/img/sidebar/download.png"
                          alt="download"
                          className=" w-100"
                        />
                      </span>
                      <div className="shortBarHideContent">
                        <div>{sidebarLabels.DownloadApp}</div>
                        <div className="downloadTitle">
                          {sidebarLabels.forIOS_And}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 }
                {/* download mobile */}
                <div className="col-12 d-lg-none cursor-pointer">
                  <div className="pt-1">
                    <Link
                      to={ROUTES_CONST.DOWNLOAD_APP}
                      className="d-flex text-decoration-none align-items-center livesupport"
                    >
                      <span className="sponsorshipIcon d-flex align-items-center justify-content-center">
                        <img
                          src="assets/img/sidebar/download.png"
                          alt="download"
                          className=" w-100"
                        />
                      </span>
                      <div>
                        <div>{sidebarLabels.DownloadApp}</div>
                        <div className="downloadTitle">
                          {sidebarLabels.forIOS_And}
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row shortBarHideContent mt-3">
              <div className="sidebarFooter">
                <div className="d-flex align-items-center justify-content-center">
                  <div
                    onClick={handleDepositClick}
                    className="getCryptoBtn d-flex align-items-center justify-content-center cursor-pointer"
                  >
                    {sidebarLabels.GET_CRYPTO}
                  </div>
                </div>
                <div className="row align-items-center justify-content-center gy-3 sidefooterImg ">
                  <div className="col-auto ps-0">
                    <div className="sidebarpayment d-flex align-items-center justify-content-center">
                      <img
                        src="assets/img/sidebar/applepay.png"
                        alt="apple pay"
                        className="h-100"
                      />
                    </div>
                  </div>
                  <div className="col-auto px-0">
                    <div className="sidebarpayment d-flex align-items-center justify-content-center">
                      <img
                        src="assets/img/sidebar/gpay.png"
                        alt="gpay"
                        className="h-100"
                      />
                    </div>
                  </div>
                  <div className="col-auto ps-0">
                    <div className="sidebarpayment d-flex align-items-center justify-content-center">
                      <img
                        src="assets/img/sidebar/dotspay.png"
                        alt="samsungPay"
                        className="h-100"
                      />
                    </div>
                  </div>
                  <div className="col-auto ps-0">
                    <div className="sidebarpayment d-flex align-items-center justify-content-center">
                      <img
                        src="assets/img/sidebar/visa.png"
                        alt="samsungPay"
                        className="h-100"
                      />
                    </div>
                  </div>
                  <div className="col-12 ps-0">
                    <div className="sidebarpayment d-flex align-items-center justify-content-center">
                      <img
                        src="assets/img/sidebar/samsungPay.png"
                        alt="samsungPay"
                        className="h-100"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>

      <DownloadModal />
      <UnAuthCurrencyModal
        isOpen={showLanguage}
        setIsOpen={setShowLanguage}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
    </>
  );
};

export default AppSideBar;