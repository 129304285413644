import React from 'react'
import { useLogoutModal } from '../../hooks/useLogout'
import { ADMIN_LOGGED_IN_ID, ADMIN_FRONTEND_LOGGED_IN_ID, BT_TOKEN_NAME, STOCK_USER_ID, TOKEN_NAME } from '../../constants';
import { useAuth } from '../../hooks/useAuth';
import { getAuth, signOut } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { getUserDetails, getSubscriptionUsers } from '../../store/action';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useSelector } from 'react-redux';
const LogoutModal = () => {
    const { isLogoutOpen, setIsLogoutOpen } = useLogoutModal()
    const { setToken } = useAuth()
    const dispatch = useDispatch()


    const logoutFunction = () => {
        localStorage.removeItem(TOKEN_NAME);
        localStorage.removeItem(BT_TOKEN_NAME);
        // adminID.removeItem()
        // adminID.updateValue("")
        // localStorage.removeItem(ADMIN_LOGGED_IN_ID)
        localStorage.removeItem(ADMIN_FRONTEND_LOGGED_IN_ID)
        localStorage.removeItem(STOCK_USER_ID)
        setToken("");
        // setBtToken("");
        const auth = getAuth();
        dispatch(getUserDetails({}))
        dispatch(getSubscriptionUsers([]));
        signOut(auth)
            .then(() => {
                // succesToaster("Logged Out")
            })
            .catch((error) => {
                // An error happened.
            });
        setIsLogoutOpen(false)
    };
    return (
        <>
            <div
                className={`modal fade logoutmodal ${isLogoutOpen ? "show" : ""}`}
                id="logoutmodal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{
                    display: isLogoutOpen ? "block" : "none",
                }}
            >
                <div className="modal-dialog logoutDialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content logoutcontent">
                        <div className="modal-header px-4 border-0">
                            <div
                                className="modal-title logouttitle d-flex align-items-center "
                                id="exampleModalLabel"
                            >
                                <span className="d-flex align-items-center justify-content-center logouticon">
                                    <img src="assets/img/sidebar/logout.png" alt="log out" className="h-100 w-100 " />
                                </span>
                                Logout
                            </div>
                            <button onClick={() => setIsLogoutOpen(false)} type="button" className="btn-close closebtn shadow-none d-flex align-items-center justify-content-center" data-bs-dismiss="modal" aria-label="Close">
                                <img src="assets/img/sidebar/close.png" alt="close" className="h-100 w-100 img-fluid" />
                            </button>
                        </div>
                        <div className="modal-body px-4">
                            <div className="logoutdata">
                                Don’t forget to check out our huge selection of casino and sports promotions before you leave!
                            </div>
                            <div className="d-flex align-items-center justify-content-center logoutbtnmain">
                                <button onClick={() => logoutFunction()} className="btn logoutbtn d-flex align-items-center justify-content-center w-100 border-0">Log out</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LogoutModal