import { Link } from "react-router-dom";
import React from "react";
import quickBetActiveIcon from "../../sportsAssets/img/quickBetActive.svg";
import errorIcon from "../../sportsAssets/img/errorIcon.svg";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ROUTES_CONST } from "../../../constants/routeConstant";
import { Spinner } from "react-bootstrap";

const QuickBets = ({
  profit,
  changeBetInputValue,
  betInputValue,
  applyBet,
  betAmount,
  setBetAmount,
  isApplyingBet,
  setIsApplyingBet,
  betType,
  oddsType
}) => {
  const [betValues, setBetValues] = useState([
    , 50, 100, 500, 1_000, 5_000, 10_000, 25_000,
  ]);
  const [error, setError] = useState();
  return (
    <>
      <div className="row mx-1 my-3 quickBetSec">
        <div className="col-12 quickBetText d-flex justify-content-between">
          {/* QuickBet mode is on! After single click on any selection, it will place your bet immediately. See all your bets on */}
          <Link className="quickBetLink" to={ROUTES_CONST.SPORTS_MY_BET_PAGE}>
            <img src="assets/img/sidebar/new/myBets.png" alt="" />
            My Bets page
          </Link>
          <div className="bolder bold text-bold fw-bolder">
            ODDS: {betAmount ? Number(betAmount)?.toFixed(2) : ""}
          </div>
        </div>
        <div className="col-12 my-3">
          <div className="row g-2 quickBetBtnWrapper">
            {betValues?.map((item) => (
              <div className="col-3" key={item}>
                <div
                  onClick={() => changeBetInputValue(item)}
                  className={`quickBetBtn d-flex align-items-center justify-content-center p-2 rounded-pill ${Number(betInputValue) === item ? "active" : ""
                    }`}
                >
                  {item}
                </div>
              </div>
            ))}

            <div className="col-3">
              <div
                onClick={() => changeBetInputValue('')}
                className={`quickBetBtn d-flex align-items-center justify-content-center p-2 rounded-pill`}
              >
                Clear
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-6 ">
              <div className="row">
                <div className="text-success fw-bold col-12 mb-2">
                  <span className="fw-normal text-dark">profit:</span> {" "}
                  {(betType === "lay" || betType === "no") &&
                    (oddsType === "bookmakers" || oddsType === "fancy")
                    ? betInputValue
                      ? betInputValue
                      : 0
                    : profit
                      ? profit
                      : 0}
                </div>
                <div className="col-12 d-flex profitBox">
                  <span
                    className="incBtn"
                    onClick={() =>
                      setBetAmount((pre) => {
                        if (Number(pre - 0.01) <= 1) {
                          return Number(1);
                        }
                        return Number(pre - 0.01);
                      })
                    }
                  >
                    {" "}
                    -{" "}
                  </span>
                  {/* <span className='text-dark bg-secondary w-25'> {!!betAmount ? Number(betAmount).toFixed(2) : "00.0"} </span> */}
                  <div className="betInputBox2">
                    <input
                      className="betInput form-control shadow-none text-dark"
                      type="text"
                      onChange={(e) => setBetAmount(Number(e.target.value))}
                      value={Number(betAmount).toFixed(2)}
                    />
                  </div>
                  <span
                    className="incBtn"
                    onClick={() =>
                      setBetAmount((pre) => {
                        if (Number(pre + 0.01) <= 1) {
                          return Number(1);
                        }
                        return Number(pre + 0.01);
                      })
                    }
                  >
                    {" "}
                    +{" "}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="text-dark col-12 mb-2">
                Stake
                {/* {betInputValue ? '$ ' + betInputValue : ""} */}
              </div>
              <div className="position-relative betInputBox w-100">
                <input
                  type="text"
                  className="betInput form-control shadow-none"
                  value={betInputValue}
                  onChange={(e) => {
                    if (!isNaN(Number(e.target.value))) {
                      if (betAmount) {
                        changeBetInputValue(e.target.value);
                        setError("");
                      } else {
                        setError("choose odds");
                      }
                    } else {
                      changeBetInputValue("");
                    }
                  }}
                />
                <span className="position-absolute top-50 dollorSign translate-middle">
                  ₹
                </span>
                {/* <button className='position-absolute top-50 applyBetBtn shadow-none' onClick={() => {
                  setIsApplyingBet(true)
                  if (!betAmount) {
                    setError("choose odds")
                    setIsApplyingBet(false)
                    return
                  }
                  applyBet()
                  setIsApplyingBet(false)
                }}>
                  {isApplyingBet ? <Spinner color='light' size='sm' /> : " Apply Bet"}
                </button> */}
              </div>
            </div>
          </div>
          <span className="text-danger">{error}</span>
        </div>
        <div className="col-12">
          <div className="row py-2 pt-3 align-items-end">
            <div className="col-6">
              <div className="totalStack">
                <span className="px-1 d-block">
                  ₹ {betInputValue ? betInputValue : 0}
                </span>
                <span className="px-1 d-block"> YOUR STAKE</span>
              </div>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <button
                className="applyBetBtn2 shadow-none"
                onClick={() => {
                  setIsApplyingBet(true);
                  if (!betAmount) {
                    setError("choose odds");
                    setIsApplyingBet(false);
                    return;
                  }
                  applyBet();
                  setIsApplyingBet(false);
                }}
              >
                {isApplyingBet ? (
                  <Spinner color="light" size="sm" />
                ) : (
                  " Apply Bet"
                )}
              </button>
            </div>
          </div>
        </div>
        {betInputValue !== "" ? (
          <div className="col-12 mt-3 quickBetMsg">
            <img src={quickBetActiveIcon} alt="" />
            Bet is active with ₹ {betInputValue} bet
          </div>
        ) : (
          <div className="col-12 mt-3 quickBetMsg">
            <img src={errorIcon} alt="" />
            Min stake₹ 0.12
          </div>
        )}
      </div>
    </>
  );
};

export default QuickBets;
