import React, { useEffect, useState } from "react";
import eventIcon from "../../sportsAssets/img/event.svg";
import gameIcon from "../../sportsAssets/img/gameIcon.svg";
import BetCard from "./BetCard";
import { sportsGetAPI } from "../../sportsServices";
import Spinner from "react-bootstrap/Spinner";
import { sportSocket } from "../../sportsServices/sportsSocket";
import { useDispatch, useSelector } from "react-redux";
import { setGameId } from "../../../store/action";

const Events = ({ hasPadding }) => {
  const [series, setSeries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCat, setSelectedCat] = useState("");
  console.log("selectedCat", selectedCat);
  const [seriesMatches, setSeriesMatches] = useState([]);
  const [isSeriesLoading, setIsSeriesLoading] = useState(true);
  const dispatch = useDispatch();
  const matchId = useSelector((state) => state.matchId.gameid);
  // const matchIcon = useSelector(state => state?.matchId);
  // console.log("fdsadsd", matchIcon);

  useEffect(() => {
    if (!!matchId) {
      setSelectedCat(matchId);
    }
  }, [matchId]);

  useEffect(() => {
    if (!!selectedCat) {
      // console.log(selectedCat);
      try {
        sportSocket.emit("matchList", { gameId: selectedCat }); // for live matches liveMatchList
        sportSocket.on(`gameId-${selectedCat}`, (data) => {
          // liveMatchList-
          console.log("socket", "--Events----", data);

          setSeriesMatches(data);

          // if (seriesMatches) {
          //   console.log(data, "kkkkkkkkkkkk");
          //   setIsSeriesLoading(false)

          // }
        });
      } catch (error) {
        console.log(error);
        setIsSeriesLoading(false);
      } finally {
        // setIsSeriesLoading(false);
      }
    }
  }, [selectedCat]);

  const matchSectionHandler = (item) => {
    sportSocket.emit("matchList", { gameId: item?.gameId }); // for live matches liveMatchList
    sportSocket.on(`gameId-${item?.gameId}`, (data) => {
      // liveMatchList-
      console.log("onClick", data);

      setSeriesMatches(data);
      setIsSeriesLoading(false);
    });
  };
  const getMatchSeries = async () => {
    try {
      const res = await sportsGetAPI("bet/games");
      if (res.data.data.success) {
        setSeries(res.data.data.data);
        if (!matchId) setSelectedCat(res.data.data.data[0]?.gameId);
        if (!matchId) dispatch(setGameId(res.data.data.data[0]?.gameId));
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  // console.log("seriesMatches", seriesMatches[0]?._id)

  useEffect(() => {
    getMatchSeries();
  }, []);

  useEffect(() => {
    if (seriesMatches.length > 0) {
      setIsSeriesLoading(false);
    }
  }, [seriesMatches]);

  console.log("seriesMatches", seriesMatches);
  // console.log("hasss", seriesMatches);
  return (
    <>
      {/* <div className="eventHeading d-flex align-items-center">
        <img src={eventIcon} alt="" className='me-2' />
        Upcoming Matches
      </div> */}
      {/* <div className="eventPillsWrapper">
        <div className="eventPills d-flex align-items-center flex-nowrap">
          {
            isLoading ? (
              <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="light" size="sm" />
              </div>
            ) : (
              series?.length === 0 ? (
                <div className="w-100 h-100">
                  <h6 className="text-light"> No data available</h6>
                </div>
              ) : (
                series?.map((item) => (
                  <div
                    onClick={() => {
                      // matchSectionHandler(item)
                      dispatch(setGameId(item?.gameId))
                      setSelectedCat(item?.gameId)

                    }}
                    className={`eventPill rounded-pill me-2 text-nowrap ${selectedCat == item?.gameId ? "active" : ""}`}>
                    <img src={item?.image} alt="" />
                    {item?.name}
                  </div>
                ))
              )
            )
          }
        </div>
      </div> */}
      {isSeriesLoading ? (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : seriesMatches === 0 ? (
        <div className="w-100 h-100">
          <h6 className="text-light"> No data available</h6>
        </div>
      ) : (
        <div className={`${hasPadding ? "mainContaint p-0 m-0 " : ""}`}>
          <div
            className={` ${hasPadding ? "sportsPage p-0 darkSection" : "p-0"}`}
            style={{ minHeight: "unset" }}
          >
            {
              selectedCat == "5" ? <>
                <></>
              </> : <>
                <div
                  // className={`${hasPadding ? `` : `row`}`}
                  className="row gx-0 mx-0 w-100"
                >
                  {seriesMatches?.map((item) => (
                    <BetCard
                      competitionName={item?.event?.name}
                      openDate={item?.event?.openDate}
                      odd1={
                        item?.matchodds?.length
                          ? item?.matchodds[0]?.runners[0]?.ex
                          : false
                      }
                      odd2={
                        item?.matchodds?.length
                          ? item?.matchodds[0]?.runners[1]?.ex
                          : false
                      }
                      odd3={
                        item?.matchodds?.length
                          ? item?.matchodds[0]?.runners[2]?.ex
                          : false
                      }
                      runners={
                        item?.matchodds ? item?.matchodds?.[0]?.runners : false
                      }
                      // marketId={item?.odds?.length ? item?.odds[0]?.marketId : false}
                      marketId={item?.marketId ? item?.marketId : false}
                      locked={!!item?.matchodds?.length ? false : true}
                      matchId={item?._id}
                      eventId={item?.event?.id}
                    />
                  ))}
                </div>

              </>
            }
          </div>
        </div>
      )}
    </>
  );
};

export default Events;
