import React, { useEffect, useState } from 'react'
import { sportSocket } from '../../sportsServices/sportsSocket'
import { useDispatch, useSelector } from 'react-redux'
import { adminGetAPIAuth } from '../../../Admin/AdminServices'
import BetSlipOffcanvas from '../../sportsComponents/betSlipOffcanvas/BetSlipOffcanvas'
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { useAuthModal } from '../../../hooks/useAuthModal';
import { getAPIAuth } from '../../../service/apiInstance';
import { sportsGetAPIAuth, sportsPostAPIAuth } from '../../sportsServices/index';
import { errorToaster, succesToaster } from '../../../utils/toaster';
import { AUTH_MODAL_TABS } from '../../../constants';
import Spinner from "react-bootstrap/Spinner";

function Binary() {
    const [CatagoriesList, setCatagoriesList] = useState([]);
    console.log('CatagoriesListCatagoriesList', CatagoriesList);
    const [selectedCatlist, setSelectedCatList] = useState('');
    const [selectedFancyKey, setSelectedFancyKey] = useState('ALL');
    const [marketgamelist, setMarketGameList] = useState([]);
    console.log("marketgamelistsdafhwersfdesadc", marketgamelist);
    // console.log("marketgamelistmarketgamelistmarketgamelist", CatagoriesList);

    // console.log("marketgamelist", marketgamelist);
    const [selectedCat, setSelectedCat] = useState('4');
    const [seriesMatches, setSeriesMatches] = useState([]);
    const [sportstabingicon, setSportsTabingIcon] = useState('')

    const dispatch = useDispatch()
    const matchId = useSelector(state => state.matchId.gameid)
    const betGameList = useSelector(state => state?.betGameList);
    const getWalletData = useSelector(state => state?.getWalletData);
    // console.log("asdqwertsxcvb", getWalletData);
    const [stockcatgoriesid, setStockCatagoriesId] = useState();
    const [matchBetsData, setMatchBetsData] = useState();
    const [betAmount, setBetAmount] = useState(0);
    const [smallamout, setSmallAmout] = useState(0);
    const [betInputValue, setBetInputValue] = useState();
    const [selectionId, setSelectionId] = useState('');

    const [profit, setProfit] = useState(0);
    const [sectionName, setSectionName] = useState('');
    const [relatedMatches, setRelatedMatches] = useState([]);
    const [relatedMatchLoader, setRelatedMatchLoader] = useState(true)
    const id = useParams()?.matchId
    const [eventId, marketId] = ("|+|")

    const [indMarketId, setIndMarketId] = useState("")
    const { token } = useAuth()
    const { authModalObject, setAuthModalObject } = useAuthModal()
    const [isApplyingBet, setIsApplyingBet] = useState(false)
    const [isBetOpen, setIsBetOpen] = useState(false)
    const [oddsType, setOddsType] = useState('')
    const [bookmakerComm, setBookmakerComm] = useState([]);
    const [bookmakerToss, setBookmakerToss] = useState([]);
    const [bookmakerTied, setBookmakerTied] = useState([]);
    const [showBet, setShowBet] = useState(false)
    const [openBets, setOpenBets] = useState([])
    const [betLoader, setBetLoader] = useState(false)
    const [itemIndex, setItemIndex] = useState(5)
    const [parentIndex, setParentIndex] = useState(5)
    const [arrType, setArrType] = useState('')
    const [secType, setSecType] = useState('')
    const [fancyKeys, setFancyKeys] = useState([]);
    // console.log("fancyKeys", fancyKeys);
    const [fancyData, setFancyData] = useState([])
    // console.log("fancyData", fancyData);
    const [blink, setBlink] = useState(false)
    const [previous, setPrevious] = useState({})
    const [previousOverUnderFootball, setpreviousOverUnderFootball] = useState([]);
    const [matchOddsFootBallTennis, setMatchOddsFootBallTennis] = useState({});
    const [overUnderDataFootball, setOverUnderDataFootball] = useState([]);
    const [marketstockCategoryId, setMarketStockCatagoriesId] = useState("");
    const [yesnovalue, setYesNOValue] = useState("");
    console.log("yesnovalue", yesnovalue);
    const [yesnosmallvalue, setYesNOSmallValue] = useState("");
    const [typemarke, setTypeMarket] = useState("");
    const [betType, setBetType] = useState('');
    const [marketName, setMarketName] = useState("");
    const [marketStockName, setMarketStockName] = useState('');
    const [isSeriesLoading, setIsSeriesLoading] = useState(true);
    const [yesnovlaue, setYesNoVaue] = useState("")




    // stockCategoryId, type, betType, yesNoValue, yesNoSmallValue

    // console.log("stockcatgoriesid", stockcatgoriesid);
    // console.log("betGameListbetGameListbetGameList", betGameList);
    const [activeGame, setActiveGame] = useState();

    //  console.log("sportstabingicon", selectedCat);

    const dummyOdds = [
        {
            price: 0,
            size: 0,
            level: 0
        },
        {
            price: 0,
            size: 0,
            level: 0
        },
        {
            price: 0,
            size: 0,
            level: 0
        }
    ]

    const getBetDelay = async () => {
        if (token) {
            try {
                const res = await getAPIAuth(`bet/getBetDelay`, token)
                // console.log('ressssssssssssssssss', res)
            } catch (error) {

            }
        }
    }

    useEffect(() => {
        getBetDelay()
    }, [])//eslint-disable-line

    // const getOpenBets = async () => {
    //     if (matchId) {
    //         try {
    //             const res = await getAPIAuth(`bet/mybets?status=open&page=1&perPage=100&matchId=${matchId}`, token)
    //             if (res?.data?.data?.success) {
    //                 setOpenBets(res?.data?.data?.data)
    //             }
    //         } catch (error) {

    //         }
    //     }
    // }


    // // for fancy section





    // useEffect(() => {
    //     getOpenBets()
    // }, [matchId]) 


    // https://stream.1ex99.com/sportRadarScore?eventId=32887420


    useEffect(() => {
        if (betType === "back") {
            applyProfit(betInputValue);
        } else {
            applyLayProfit(betInputValue)
        }
        //eslint-disable-next-line
    }, [betAmount, betType])

    useEffect(() => {
        // console.log('ready for socket emit');
        let socketInterval;
        if (id) {
            const fetchData = () => {
                // setPrevious(matchBetsData?.[0]?.matchodds?.[0]?.runners)
                // sportSocket.emit('matchDetails', { _id: "6530dccb291ec90f34117e65" });
                sportSocket.emit('matchDetails', { _id: matchId });
                // sportSocket.on(`match-32727482`, (data) => {
                sportSocket.on(`match-${eventId}`, (data) => {

                    setMatchBetsData(data);
                    if (data?.[0]?.gameId === "1" || data?.[0]?.gameId === "2") {
                        const filter = data?.[0]?.matchodds?.filter((item) => item.market === "Match Odds")
                        setMatchOddsFootBallTennis(filter[0]);
                    }

                    if (data?.[0]?.gameId === "1") {
                        const filter = data?.[0]?.matchodds?.filter((item) => item.market !== "Match Odds")
                        setOverUnderDataFootball(filter);
                    }

                });

            };

            fetchData();
            socketInterval = setInterval(fetchData, 1000);
        }

        return () => {
            if (id) {
                clearInterval(socketInterval);
            }
        };
        //eslint-disable-next-line
    }, [id]);


    console.log('matchhhhhhh', matchBetsData)


    useEffect(() => {
        setBlink(true);
        let timeout = setTimeout(() => {
            setBlink(false);
            setPrevious(matchBetsData?.[0]?.matchodds?.[0]?.runners)
            setpreviousOverUnderFootball(overUnderDataFootball);
        }, 300);

        return () => {
            // if (previous?.bid_price !== apiTableData?.bid_price) {
            clearTimeout(timeout);
            // }
        }
    }, [matchBetsData]);//eslint-disable-line

    const setBettingSystem = (marketId, stockCategoryId, type, betType, yesNoValue, yesNoSmallValue, marketname, stockName) => {

        console.log("csvxdzsfxgc", yesNoValue);

        setIndMarketId(marketId);
        setMarketStockCatagoriesId(stockCategoryId);
        setTypeMarket(type);
        setBetType(betType);
        setYesNOValue(yesNoValue);
        setYesNOSmallValue(yesNoSmallValue);
        setMarketName(marketname);
        setMarketStockName(stockName)






    }

    /** calculate the profit amount */
    const applyProfit = (inputValue) => {
        setProfit((inputValue * betAmount) - inputValue);
    }

    const applyLayProfit = (inputValue) => {
        setProfit(inputValue * (betAmount - 1))
    }
    /** configure the input value */
    const changeBetInputValue = (inputValue) => {
        setBetInputValue(inputValue);
        if (oddsType === "bookmakers" || oddsType === "fancy") {
            if (betType === "back" || betType === "yes") {
                setProfit((inputValue * betAmount) / 100);
            } else {
                setProfit((inputValue * betAmount) / 100)
            }
        } else {
            if (betType === "back") {
                applyProfit(inputValue);
            } else {
                applyLayProfit(inputValue)
            }
        }
    }

    const changeBetAmount = (value) => {
        setBetAmount((pre) => pre + value);
    };
    // get related matches

    const getRelatedMatches = async () => {
        setRelatedMatchLoader(true)
        try {
            const res = await sportsGetAPIAuth(
                `bet/getMatchList?seriesId=${matchBetsData?.seriesId}`
            )
            if (res?.data?.data?.success) {
                setRelatedMatches(res?.data?.data?.data);
            }
        } catch (error) {
        } finally {
            setRelatedMatchLoader(false);
        }
    };

    useEffect(() => {
        if (matchBetsData?.seriesId) {
            getRelatedMatches()
        }
        //eslint-disable-next-line
    }, [matchBetsData?.seriesId])
    /** bet apply  */
    const applyBet = async () => {
        if (!betInputValue) {
            errorToaster("Please add amount to stack")
            return
        }
        if (token) {
            setBetLoader(true)
            placigBet()
        } else {
            setAuthModalObject({
                isAuthOpen: true,
                selectedTab: AUTH_MODAL_TABS.LOG_IN
            })
        }
    }

    // console.log('matchBetsData', matchBetsData)
    const placigBet = async () => {
        let updatedPrice = 0;
        try {
            if (secType === 'oddsSection') {
                const dataClone = structuredClone(matchBetsData?.[0]?.matchodds?.[0]?.runners)
                if (arrType === 'availableToBack') {
                    updatedPrice = dataClone?.[parentIndex]?.ex?.availableToBack?.reverse()?.[itemIndex]?.price
                } else if (arrType === 'availableToLay') {
                    updatedPrice = dataClone?.[parentIndex]?.ex?.availableToLay?.[itemIndex]?.price
                }
            } else if (secType === 'bookmakerSec') {
                if (arrType === 'availableToBack') {
                    updatedPrice = matchBetsData?.[0]?.bookmakersOdds?.[0]?.runners?.[parentIndex]?.back?.[0]?.price
                } else if (arrType === 'availableToLay') {
                    updatedPrice = matchBetsData?.[0]?.bookmakersOdds?.[0]?.runners?.[parentIndex]?.lay?.[0]?.price
                }
            } else if (secType === 'bookmakerTossSec') {
                if (arrType === 'availableToBack') {
                    updatedPrice = matchBetsData?.allOddsList?.Bookmaker?.[parentIndex]?.b1
                } else if (arrType === 'availableToLay') {
                    updatedPrice = matchBetsData?.allOddsList?.Bookmaker?.[parentIndex]?.l1
                }
            } else if (secType === 'fancySec') {
                if (arrType === 'availableToBack') {
                    if (selectedFancyKey === 'oddeven' || selectedFancyKey === 'fancy1') {
                        updatedPrice = fancyData?.[parentIndex]?.BackPrice1
                    } else {
                        updatedPrice = fancyData?.[parentIndex]?.BackSize1
                    }
                } else if (arrType === 'availableToLay') {
                    if (selectedFancyKey === 'oddeven' || selectedFancyKey === 'fancy1') {
                        updatedPrice = fancyData?.[parentIndex]?.LayPrice1
                    } else {
                        updatedPrice = fancyData?.[parentIndex]?.LaySize1
                    }
                }
            }
            // console.log('updatedPrice', updatedPrice, parentIndex)
            // const params = {
            //     stake: betInputValue,
            //     // matchId: "6530dccb291ec90f34117e65",
            //     packageSubscription: null,
            //     matchId: matchId,
            //     marketId: oddsType === "odds" ? matchBetsData?.[0]?.matchodds?.[0]?.marketId : indMarketId,
            //     selectionId: selectionId,
            //     odds: Number(2.00),
            //     type: oddsType,
            //     betType: betType,
            //     marketType: marketName,
            //     outcomeDesc: matchBetsData?.[0]?.event?.name,
            //     marketName: marketName,
            //     eventId: matchBetsData?.[0]?.eventId,

            // }
            const params = {

                stake: betInputValue,
                marketId: indMarketId,
                stockId: marketstockCategoryId,
                type: "stock",
                betType: betType,
                odds: Number(yesnovalue),
                yesNoValue: yesnovalue,
                yesNoSmallValue: yesnosmallvalue,
                marketName: marketName,
                StockName: marketStockName



            }

            console.log("qwertyuioasdfghzxcvbn", params);
            //   {
            //     "amount": 1000,
            //     "matchId": "653f2c891e72577aca7dfc2a",
            //     "marketId": "1.220184750",
            //     "selectionId": "7337",
            //     "potentialAmount": 110,
            //     "odds":"1.90",
            //     "type":"odds"
            // }
            // type: odds || bookmakers || fancy


            const res = await sportsPostAPIAuth('bet/place-stock', params, token);
            if (res?.status) {
                setBetAmount(0);
                setBetType('');
                setSelectionId("");
                setProfit(0);
                setBetInputValue("");
                setSectionName('');
                succesToaster(res?.data?.data?.message)
                // getOpenBets()
                getWalletData.getUserBal()

            } else {
                errorToaster(res.data.message)
            }

        } catch (error) {
            console.log('catch error', error)

            if (error.message === "Request failed with status code 401") return errorToaster("Unauthorized error")
            errorToaster(error?.response?.data?.data?.message)
        } finally {
            setBetLoader(false)
        }
    }

    useEffect(() => {
        const data = structuredClone(matchBetsData?.[0]?.bookmakersOdds);
        const bookmakerCommData = data?.filter(
            (item) => item?.mname === "Bookmaker"
        );
        const bookmakerTiedData = data?.filter(
            (item) => item?.mname === "Tied Match"
        );
        const bookmakerTossData = data?.filter((item) => item?.mname === "TOSS");
        setBookmakerComm(bookmakerCommData);
        setBookmakerToss(bookmakerTossData);
        setBookmakerTied(bookmakerTiedData)
    }, [matchBetsData?.[0]?.bookmakersOdds?.[0]]);


    useEffect(() => {
        const data = structuredClone(matchBetsData?.[0]?.matchfancies)

        const gTypeSet = new Set();

        data?.forEach(obj => {
            gTypeSet?.add(obj?.gtype)
        })

        const gTypeArray = [...gTypeSet]
        setFancyKeys(gTypeArray)
        // setSelectedFancyKey(gTypeArray?.[0])

    }, [matchBetsData?.[0]?.matchfancies])//eslint-disable-line

    useEffect(() => {
        if (selectedFancyKey) {
            return
        } else {
            setSelectedFancyKey(fancyKeys?.[0])

        }
    }, [fancyKeys, selectedFancyKey])

    useEffect(() => {
        const data = structuredClone(matchBetsData?.[0]?.matchfancies)
        const filteredData = data?.filter(item => item?.gtype?.toLowerCase() === selectedFancyKey?.toLowerCase())
        setFancyData(filteredData)
    }, [selectedFancyKey])//eslint-disable-line





    // in case there are less than 3 data in match odds
    const returnMatchOdds = (data) => {
        switch (data?.length) {
            case 3:
                return data;
            case 2:
                return [...data, dummyOdds?.[0]]
            case 1:
                return [...data, dummyOdds?.[0], dummyOdds?.[1]];
            case 0:
                return [...dummyOdds];
            default:
                return data;
        }
    }

    useEffect(() => {
        if (!!matchId) {
            setSelectedCat(matchId)
        }
    }, [matchId])

    useEffect(() => {
        if (betGameList?.length) {
            const data = structuredClone(betGameList)
            let name = data?.filter(item => item?.gameId === matchId)
            setActiveGame(name?.[0]?.name)
        }
    }, [matchId, betGameList])



    // const matchSectionHandler = (item) => {
    //     sportSocket.emit('matchList', { gameId: item?.gameId })// for live matches liveMatchList
    //     sportSocket.on(`gameId-${item?.gameId}`, (data) => { // liveMatchList-
    //         console.log('onClick', data);
    //         setSeriesMatches(data)
    //     })
    // }

    // console.log("hasPaddding", hasPadding)

    const GameCatagoriesList = async () => {
        try {
            const res = await adminGetAPIAuth(
                "market/getStockCategory"
            );

            console.log("qwerty", res);
            if (res?.data?.status === 200) {
                setCatagoriesList(res?.data?.data?.data);
            }
        } catch (error) {
            setCatagoriesList([]);
        }
    };

    const getMarketGameList = async () => {
        let apiEndpoint = '';
        if (!selectedFancyKey) {
            apiEndpoint = `market/getMarketGame`;
        } else if (selectedFancyKey === "ALL") {
            apiEndpoint = `market/getMarketGame`;
        } else if (selectedFancyKey) {
            apiEndpoint = `market/getMarketGame?stockCategoryId=${stockcatgoriesid}`;
        }
        try {
            const res = await adminGetAPIAuth(
                apiEndpoint
            );

            console.log("qwerty", res);
            if (res?.data?.status === 200) {
                setMarketGameList(res?.data?.data?.data);

                setIsSeriesLoading(false)
            }
        } catch (error) {
            setMarketGameList([]);
        }
        finally {
            setIsSeriesLoading(false)
        }
    };

    useEffect(() => {
        GameCatagoriesList();
    }, []);

    useEffect(() => {

        getMarketGameList();

    }, [selectedFancyKey, stockcatgoriesid]);




    return (
        <>
            <main className="main vh-100" id="main">
                <div className="row">


                    <div className="col-12 mt-3">
                        <div className="row">

                            {/** Section started to display the bets */}
                            <div className="col-lg-8">

                                {
                                    CatagoriesList?.length ?
                                        <>
                                            <div className="main-wapper-tabing-catagories d-flex">
                                                <div class="eventPillsWrapper mb-0">
                                                    <div class="eventPills d-flex align-items-center flex-nowrap" >
                                                        <div onClick={() => setSelectedFancyKey("ALL")} className={`eventPill rounded-pill me-2 text-nowrap text-capitalize ${selectedFancyKey === "ALL" ? 'active' : ''}`}>
                                                            ALL
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="eventPillsWrapper mb-0">
                                                    <div class="eventPills d-flex align-items-center flex-nowrap">
                                                        {
                                                            CatagoriesList?.map((item) => (
                                                                <div onClick={() => {
                                                                    setSelectedFancyKey(item?.name);
                                                                    setStockCatagoriesId(item?._id)

                                                                }} className={`eventPill rounded-pill me-2 text-nowrap text-capitalize ${selectedFancyKey === item?.name ? 'active' : ''}`}>
                                                                    {item?.name}
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        : ''
                                }

                                {/* check */}
                                <div className="matchBettingSection">
                                    <div className="matchTextSection">
                                        <div className="matchTxtSecTitle text-capitalize">{selectedFancyKey}</div>
                                    </div>
                                </div>
                                <div className="d-flex fancyHalfContainerWrapper">
                                    <div className="matchOddsDetailsSection d-flex align-items-center justify-content-between prDiv fancyHalfContainer">
                                        <div className="matchesBetLayFirst flex-fill">Market</div>
                                        <div className="d-flex" style={{ gap: 2 }}>
                                            <div className="matchesLaySection text-uppercase d-flex align-items-center justify-content-center shorttt">
                                                <span>
                                                    {
                                                        selectedFancyKey === "oddeven" ? 'ODD' :
                                                            selectedFancyKey === "meter" ? 'NO' :
                                                                selectedFancyKey === "fancy1" ? 'LAY' :
                                                                    selectedFancyKey === "session" ? 'NO' :
                                                                        selectedFancyKey === "khado" ? 'LAY' : 'NO'
                                                    }
                                                </span>
                                            </div>
                                            <div className="matchesBackSection d-flex align-items-center justify-content-center shorttt">
                                                <span>
                                                    {
                                                        selectedFancyKey === "oddeven" ? 'EVEN' :
                                                            selectedFancyKey === "meter" ? 'YES' :
                                                                selectedFancyKey === "fancy1" ? 'BACK' :
                                                                    selectedFancyKey === "session" ? 'YES' :
                                                                        selectedFancyKey === "khado" ? 'BACK' : 'YES'
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="matchOddsDetailsSection d-none d-lg-flex align-items-center justify-content-between fancyHalfContainer">
                                        <div className="matchesBetLayFirst flex-fill">Market</div>
                                        <div className="d-flex" style={{ gap: 2 }}>
                                            <div className="matchesLaySection text-uppercase d-flex align-items-center justify-content-center shorttt">
                                                <span>
                                                    {
                                                        selectedFancyKey === "oddeven" ? 'ODD' :
                                                            selectedFancyKey === "meter" ? 'NO' :
                                                                selectedFancyKey === "fancy1" ? 'LAY' :
                                                                    selectedFancyKey === "session" ? 'NO' :
                                                                        selectedFancyKey === "khado" ? 'LAY' : 'NO'
                                                    }
                                                </span>
                                            </div>
                                            <div className="matchesBackSection d-flex align-items-center justify-content-center shorttt">
                                                <span>
                                                    {
                                                        selectedFancyKey === "oddeven" ? 'EVEN' :
                                                            selectedFancyKey === "meter" ? 'YES' :
                                                                selectedFancyKey === "fancy1" ? 'BACK' :
                                                                    selectedFancyKey === "session" ? 'YES' :
                                                                        selectedFancyKey === "khado" ? 'BACK' : 'YES'
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* check */}
                                <div className="matchesBetLaySection fancyHalfContainerWrapper">
                                    {isSeriesLoading ? (
                                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                            <Spinner animation="border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        </div>
                                    ) : (

                                        <>

                                            {
                                                marketgamelist?.length < 1 ? <>No market Data</> : (
                                                    <>
                                                        {marketgamelist?.map((fancyData, pIndex) => {

                                                            // console.log('fancyData', fancyData);
                                                            return (
                                                                <div className="matchesBetLayContainer fancyContainer fancyHalfContainer stockmanage" key={fancyData?.nation}>
                                                                    <div className="matchesBetLayFirst flex-fill">
                                                                        {fancyData?.name?.toLowerCase()}
                                                                        {/* {returnExposerAmount(fancyData?.SelectionId)} */}
                                                                    </div>
                                                                    {/* <div
                                                className={`matchesBetLaySecond ${fancyData.status !== 'active' ? "suspend" : ""
                                                    }`}
                                                > */}
                                                                    <div
                                                                        className={`matchesBetLaySecond ${fancyData.status !== 'active' ? "active" : ""
                                                                            }`}
                                                                    >
                                                                        <div className="suspendDiv d-none">{fancyData?.status}</div>

                                                                        <div className="main-cat">

                                                                            <div
                                                                                className={`matchesLaySection ${fancyData?.no && fancyData?.no ? " " : "disabled "
                                                                                    } `}

                                                                                // marketId, stockCategoryId, type, betType, yesNoValue, yesNoSmallValue
                                                                                onClick={() => {
                                                                                    setBettingSystem(
                                                                                        fancyData?._id,
                                                                                        fancyData?.stockId,
                                                                                        "no",
                                                                                        "lay",
                                                                                        fancyData?.no,
                                                                                        fancyData?.noSmall,
                                                                                        fancyData?.name,
                                                                                        fancyData?.stockName


                                                                                    )

                                                                                    setSecType('fancySec')
                                                                                    setYesNoVaue(fancyData?.no)
                                                                                    setArrType('availableToLay')
                                                                                    setParentIndex(pIndex)
                                                                                    setShowBet(true)
                                                                                }
                                                                                }
                                                                            >
                                                                                <span> {fancyData?.no} </span>
                                                                                <span> {fancyData?.noSmall} </span>
                                                                            </div>

                                                                        </div>

                                                                        <div className="main-cat">
                                                                            <div
                                                                                className={`matchesBackSection ${fancyData?.yes && fancyData?.yes ? " " : "disabled "
                                                                                    } `}
                                                                                onClick={() => {
                                                                                    setBettingSystem(
                                                                                        fancyData?._id,
                                                                                        fancyData?.stockId,
                                                                                        "yes",
                                                                                        "back",
                                                                                        fancyData?.yes,
                                                                                        fancyData?.yesSmall,
                                                                                        fancyData?.name,
                                                                                        fancyData?.stockName
                                                                                    )
                                                                                    setYesNoVaue(fancyData?.no)
                                                                                    setSecType('fancySec')
                                                                                    setArrType('availableToBack')
                                                                                    setParentIndex(pIndex)
                                                                                    setShowBet(true)
                                                                                }
                                                                                }
                                                                            >
                                                                                <span> {fancyData?.yes} </span>
                                                                                <span> {fancyData?.yesSmall} </span>
                                                                            </div>


                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            );
                                                        })}
                                                    </>
                                                )
                                            }


                                        </>
                                    )
                                    }


                                </div>


                            </div>
                            <div className={`col-lg-4 mt-4 mt-lg-0 betSlipOffcanvasParent ${showBet ? "show" : ""}`}>

                                <BetSlipOffcanvas
                                    profit={betInputValue} // profit
                                    betInputValue={betInputValue} //  amout stack value 
                                    changeBetInputValue={changeBetInputValue} // change value
                                    applyBet={applyBet} // apply bets
                                    betAmount={yesnovalue}
                                    // betAmount={2.00} // ooddds
                                    setBetAmount={setBetAmount} // increment decrement  
                                    isApplyingBet={isApplyingBet}  // loader value 
                                    setIsApplyingBet={setIsApplyingBet} // set loader value                                    
                                    betType={betType}
                                    oddsType={oddsType}
                                    setShowBet={setShowBet}
                                />
                                {/* const setBettingSystem = (type, price, samll, selectionId, sectionName, oddType, marketN, iMarketId,) => {
                                    setBetAmount(price);
                                setSmallAmout(samll);
                                setBetType(type);
                                setSelectionId(selectionId);
                                setProfit(0);
                                setSectionName(sectionName);
                                setBetInputValue("")
                                setIsBetOpen(true)
                                setOddsType(oddType)
                                setMarketName(marketN)
                                setIndMarketId(iMarketId)
        // setItemIndex(index)
    } */}
                            </div>

                        </div>
                    </div>
                </div>

            </main>

        </>
    )
}

export default Binary