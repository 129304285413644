import React, { useEffect, useState } from 'react';
import graph from '../../assets/graph.webp';
import { useAuth } from '../../hooks/useAuth';
import { getAPIAuth } from '../../service/apiInstance';
import moment from 'moment';
import { Button } from 'react-bootstrap';

const SubcriptionDetailes = () => {
        const [subscriptiondata, setSubscriptionData] = useState();
        const targetDate = new Date(subscriptiondata?.lastValidDate);
        const currentDate = new Date();
        const timeDiff = targetDate - currentDate;
        const daysLeft = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hoursLeft = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

        const { token } = useAuth();

        useEffect(() => {
            getSubscriptionGetAll()
        }, [])

        const getSubscriptionGetAll = async () => {
            if (token) {
                try {
                    const res = await getAPIAuth("user/getUserPackageInfo", token);
                    console.log("res", res);
                    if (res?.data?.data?.success) {
                        setSubscriptionData(res?.data?.data?.data);
                    }
                } catch (error) {
                    console.log("error", error);
                }
            }
        };
    console.log(subscriptiondata, "subscriptiondata");
    return (
        <main className="main vh-100" id="main">
            <div className="mainContaint">
                <div className="homepage tradingPages">
                    <div className="row mx-0 mt-2">
                        <div className="col-12 px-0">
                            <div className="row pb-1 pt-1 g-3 align-items-center ">
                                <div class=" col-md-12 mx-4 adminTopCard row mb-4 w-25">
                                    {/* <div class=""> */}
                                        {/* <div class=" p-3 purple-border"> */}
                                        <div class="adminTopCardSubtitle fs-2 text-warning mb-1 ">{subscriptiondata?.package?.name}</div>
                                            <h4 className='adminTopCardTitle'>Price</h4>
                                            <div class="adminTopCardTitle mb-2">₹ {subscriptiondata?.package?.price}/ per Week</div>
                                            
                                        {/* </div> */}
                                    {/* </div> */}
                                    {/* <div class="col-md-4">
                                    <div class="btn btn-secondary mt-3 ">{subscriptiondata?.archived? "Archived":"UnArchived"}</div>
                                     </div> */}
                                </div>
                                <div class="col-md-12 p-4">
                                    <div class="row align-items-strentch">
                                        <div class="col-md-4">
                                            <div class="adminTopCard text-white p-3 purple-border h-100">
                                            <div className='d-flex align-items-center gap-2'><h4 className='adminTopCardSubtitle'>Net Profit: </h4><p className='adminTopCardTitle'>$ {subscriptiondata?.subscriptionInfo?.net_profit}</p></div>
                                            <div className='d-flex align-items-center gap-2'><h4 className='adminTopCardSubtitle'>Net Loss: </h4><p className='adminTopCardTitle'>$ {subscriptiondata?.subscriptionInfo?.net_loss}</p></div>
                                            <div className='d-flex align-items-center gap-2'><h4 className='adminTopCardSubtitle'>Leverage: </h4><p className='adminTopCardTitle'>{subscriptiondata?.package?.leverage}  x</p></div>
                                            {/* <div className='d-flex align-items-center gap-2'><h4 className='adminTopCardSubtitle'>Last Bet: </h4><p className='adminTopCardTitle'>N/A</p></div> */}
                                            <div className='d-flex align-items-center gap-2'><h4 className='adminTopCardSubtitle'>Min. Bet: </h4><p className='adminTopCardTitle'>$ {subscriptiondata?.package?.min_picks}</p></div>
                                            <div className='d-flex align-items-center gap-2'><h4 className='adminTopCardSubtitle'>Min. Bet Amount: </h4><p className='adminTopCardTitle'>$ {subscriptiondata?.package?.min_pick_ammount}</p></div>
                                            <div className='d-flex align-items-center gap-2'><h4 className='adminTopCardSubtitle'>Max. Bet Amount: </h4><p className='adminTopCardTitle'>$ {subscriptiondata?.package?.min_pick_ammount}</p></div>
                                            
                                                                                          </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="adminTopCard text-white p-3 purple-border h-100">
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div className='adminTopCardSubtitle'>Pick Objectives</div>
                                                    <button className='border-0 progressBtn'>
                                                        <span className='dotSpan'>

                                                        </span>
                                                        In Progress
                                                    </button>
                                                </div>
                                                <div className='d-flex align-items-center gap-2 py-2'>
                                                    <div className='adminTopCardSubtitle'># of Picks: </div>
                                                    <div className='w-100'>
                                                        <div>$ {subscriptiondata?.package?.totalBetCount}</div>
                                                        <div class="progress bg-white w-100" style={{ height: '4px' }} >
                                                            <div class="progress-bar w-0 progressBar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center gap-2 py-2'>
                                                    <div className='adminTopCardSubtitle'>Profit Target: </div>
                                                    <div className='w-100'>
                                                        <div>$ {subscriptiondata?.package?.profit_target}</div>
                                                        <div class="progress bg-white w-100" style={{ height: '4px' }} >
                                                            <div class="progress-bar w-75 progressBar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>

                                                    </div>
                                                </div>
                                                {/* <div className='d-flex align-items-center gap-2 py-2'>
                                                    <div className='adminTopCardSubtitle'>Min. Daily Loss: </div>
                                                    <div class="progress bg-white w-100" style={{ height: '4px' }} >
                                                        <div class="progress-bar w-50 progressBar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div> */}
                                                <div className='d-flex align-items-center gap-2 py-2'>
                                                    <div className='adminTopCardSubtitle'>Max. Loss: </div>
                                                    <div className='w-100'>
                                                        <div>$ {subscriptiondata?.package?.maximun_loss}</div>
                                                        <div class="progress bg-white w-100" style={{ height: '4px' }} >
                                                            <div class="progress-bar w-50 progressBar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="adminTopCard text-white p-3 purple-border h-100">
                                                <p className='adminTopCardSubtitle'>Challenge Info</p>
                                                <div className='d-flex align-items-center gap-2'><h4 className='adminTopCardSubtitle'>Time Remaining: </h4>
                                                    <p className='adminTopCardTitle'>{daysLeft} Days, {hoursLeft} Hours</p>
                                                    {/* <p>{currentUdateDate?.add(subscriptiondata?.package?.subscription_period?.totalDays, 'days').format("MMM DD YYYY , HH:mm")}</p> */}
                                                </div>
                                                <div className='d-flex align-items-center gap-2'><h4 className='adminTopCardSubtitle'>Start Date: </h4><p className='adminTopCardTitle'>{moment(subscriptiondata?.createdAt).format("MMM DD YYYY")}</p></div>
                                                <div className='d-flex align-items-center gap-2'><h4 className='adminTopCardSubtitle'>End Date: </h4><p className='adminTopCardTitle'>{moment(subscriptiondata?.lastValidDate).format("MMM DD YYYY")}</p></div>
                                                <div className='d-flex align-items-center gap-2'><h4 className='adminTopCardSubtitle'>Maximum Profit: </h4><p className='adminTopCardTitle'>$ {subscriptiondata?.package?.maximun_profit}</p></div>
                                                <div className='d-flex align-items-center gap-2'><h4 className='adminTopCardSubtitle'>Challenge Fee: </h4><p className='adminTopCardTitle'>$ {subscriptiondata?.package?.challenge_fee}</p></div>
                                                <div className='d-flex align-items-center gap-2'><h4 className='adminTopCardSubtitle'>Account Size: </h4><p className='adminTopCardTitle'>$ {subscriptiondata?.package?.account_size}</p></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-strentch gy-4">
                                    <div class="col-md-3">
                                        <div class="adminTopCard p-3 text-white purple-border h-100 d-flex align-items-center gap-4 justify-content-between">
                                            <div className='adminTopCardHeading'>Number of Bets</div>
                                            <div className='adminTopCardPara'>{subscriptiondata?.package?.totalBetCount}</div>
                                            {/* <div class="row mb-12">
                                                <div class="col-md-4">
                                                    <p className='adminTopCardTitle'>Last Bet</p>
                                                </div>
                                                <div class="col-md-8">
                                                    <img src={graph} alt="" width={130} height={60} />
                                                </div>
                                                <p>N/A</p>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="adminTopCard p-3 text-white purple-border h-100 d-flex align-items-center gap-4 justify-content-between">
                                            <div className='adminTopCardHeading'>Highest Winning Bet</div>
                                            <div className='adminTopCardPara'>$ {subscriptiondata?.subscriptionInfo?.hi_winning_bet}</div>
                                            {/* <div class="row mb-12">
                                                <div class="col-md-4">
                                                    <p className='adminTopCardTitle'>Average Winning Bet</p>
                                                </div>
                                                <div class="col-md-8">
                                                    <img src={graph} alt="" width={130} height={60} />
                                                </div>
                                                <p>N/A</p>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="adminTopCard p-3 text-white purple-border h-100 d-flex align-items-center gap-4 justify-content-between">
                                            <div className='adminTopCardHeading'>Bets Won</div>
                                            <div className='adminTopCardPara'> {subscriptiondata?.subscriptionInfo?.bets_won}</div>
                                            {/* <div class="row mb-12">
                                                <div class="col-md-4">
                                                    <p className='adminTopCardTitle'>Average Winning Bet</p>
                                                </div>
                                                <div class="col-md-8">
                                                    <img src={graph} alt="" width={130} height={60} />
                                                </div>
                                                <p>N/A</p>
                                            </div> */}
                                        </div>
                                    </div>
                                    {/* <div class="col-md-3">
                                        <div class="adminTopCard p-3 text-white purple-border h-100 d-flex align-items-center gap-4 justify-content-between">
                                            <div className='adminTopCardHeading'>Bets Loss</div>
                                            <div className='adminTopCardPara'> {subscriptiondata?.subscriptionInfo?.loss_rate}</div>
                                            <div class="row mb-12">
                                                <div class="col-md-4">
                                                    <p className='adminTopCardTitle'>Average Losing Bet</p>
                                                </div>
                                                <div class="col-md-8">
                                                    <img src={graph} alt="" width={130} height={60} />
                                                </div>
                                                <p>N/A</p>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div class="col-md-3">
                                        <div class="adminTopCard p-3 text-white purple-border h-100 d-flex align-items-center gap-4 justify-content-between">
                                            <div className='adminTopCardHeading'>Win Rate</div>
                                            <div className='adminTopCardPara'>{subscriptiondata?.subscriptionInfo?.win_rate} %</div>
                                            {/* <div class="row mb-12">
                                                <div class="col-md-4">
                                                    <p className='adminTopCardTitle'>Lowest Win Rate</p>
                                                </div>
                                                <div class="col-md-8">
                                                    <img src={graph} alt="" width={130} height={60} />
                                                </div>
                                                <p>N/A</p>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="adminTopCard p-3 text-white purple-border h-100 d-flex align-items-center gap-4 justify-content-between">
                                            <div className='adminTopCardHeading'>Loss Rate</div>
                                            <div className='adminTopCardPara'> {subscriptiondata?.subscriptionInfo?.loss_rate} %</div>
                                            {/* <div class="row mb-12">
                                                <div class="col-md-4">
                                                    <p className='adminTopCardTitle'>Average Profit Factor</p>
                                                </div>
                                                <div class="col-md-8">
                                                    <img src={graph} alt="" width={130} height={60} />
                                                </div>
                                                <p>N/A</p>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="adminTopCard p-3 text-white purple-border h-100 d-flex align-items-center gap-4 justify-content-between">
                                            <div className='adminTopCardHeading'>Average Bet Size</div>
                                            <div className='adminTopCardPara'>$ {subscriptiondata?.subscriptionInfo?.avg_bet_size}</div>
                                            {/* <div class="row mb-12">
                                                <div class="col-md-4">
                                                    <p className='adminTopCardTitle'>Average Bet Size</p>
                                                </div>
                                                <div class="col-md-8">
                                                    <img src={graph} alt="" width={130} height={60} />
                                                </div>
                                                <p>N/A</p>
                                            </div> */}
                                        </div>
                                    </div>
                                    {/* <div class="col-md-3">
                                        <div class="adminTopCard p-3 text-white purple-border h-100 d-flex align-items-center gap-4 justify-content-between">
                                            <div className='adminTopCardHeading'>Average Profit Per Bet</div>
                                            <div className='adminTopCardPara'>60%</div>
                                            <div class="row mb-12">
                                                <div class="col-md-4">
                                                    <p className='adminTopCardTitle'>Average Profit Factor</p>
                                                </div>
                                                <div class="col-md-8">
                                                    <img src={graph} alt="" width={130} height={60} />
                                                </div>
                                                <p>N/A</p>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default SubcriptionDetailes
