import { useLocation, useNavigate } from "react-router-dom";
import { ADMIN_ACCOUNT_STATEMENT_URL, ALERT_BETS_URL, BANK_DETAILS_URL, BET_LIST_URL, CASINO_REPORT_URL, COMMISSION_REPORT_URL, CRYPTO_DETAILS_URL, DASHBOARD_URL, DISPUTED_BETS_URL, GAME_REPORT_URL, MY_ACCOUNT_STATEMENT_URL, PORTAL, PROFIT_AND_LOSS_STATEMENT_URL, QR_DETAILS_URL, UPI_DETAILS_URL, USER_ACCOUNT_STATEMENT_URL, USER_MANAGEMENT_URL, CRYPTO_VIEW_PAYMENTS,ADMIN_VIEW_PAYMENTS,ADMIN_VIEW_BONUS, ADMIN_SETTING } from "../../AdminConstants/urlPaths";
import "./sidebar.css"
import { useSelector } from "react-redux";

//eslint-disable-next-line
const AdminSideBar = ({ showSideBar, setShowSideBar }) => {
  const adminData = useSelector(state => state.adminData)
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const handleClick = (url) => {
    navigate(url)
    setShowSideBar(false)
  }

  return (
    <>
      {showSideBar ?
        <aside className='app-aside app-aside-light admin-sidebar show asideCustom'>
          {/* .aside-content */}
          <div className="backdropCustom"
            onClick={() => setShowSideBar(false)}
          ></div>
          <div className="aside-content">
            {/* .aside-header */}
            <header className="aside-header">
              {/* toggle menu */}
              <button
                className="hamburger hamburger-squeeze active"
                onClick={() => setShowSideBar(false)}
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>{" "}
              {/* /toggle menu */}
              <div
                id="dropdown-aside"
                className="dropdown-aside d-md-none collapse"
              >
                {/* dropdown-items */}
              </div>
              {/* /.dropdown-aside */}
              {/* .top-bar-brand */}
              <div className="top-bar-brand bg-primary"></div>
              {/* /.top-bar-brand */}
            </header>
            {/* /.aside-header */}

            <div className="aside-menu ps ps--active-y px-3">
              <nav
                id="stacked-menu"
                className="stacked-menu stacked-menu-has-collapsible"
              >
                {/* .menu */}
                <ul className="menu">
                  <li className={`menu-item admin-sidebar-menu-item ${pathname.includes(DASHBOARD_URL) ? "active" : ""}`}  >
                    <div className="menu-link" onClick={() => handleClick(`/${PORTAL}/${adminData.role}/${DASHBOARD_URL}`)} >
                      <span className="menu-icon fas fa-home">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="w-100 h-100" xmlns="http://www.w3.org/2000/svg"><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z"></path></svg>
                      </span>{" "}
                      <span className="menu-text">Dashboard</span>
                    </div>
                  </li>
                  <li className={`menu-item admin-sidebar-menu-item ${pathname.includes(USER_MANAGEMENT_URL) ? "active" : ""}`}  >
                    <div className="menu-link" onClick={() => handleClick(`/${PORTAL}/${adminData.role}/${USER_MANAGEMENT_URL}`)} >
                      <span className="menu-icon fas fa-home">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="w-100 h-100" xmlns="http://www.w3.org/2000/svg"><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z"></path></svg>
                      </span>{" "}
                      <span className="menu-text">User Management</span>
                    </div>
                  </li>

                  <li className={`menu-item admin-sidebar-menu-item ${pathname.includes(BET_LIST_URL) ? "active" : ""}`}>
                    <div className="menu-link" onClick={() => handleClick(`/${PORTAL}/${adminData.role}/${BET_LIST_URL}`)} >
                      <span className="menu-icon fas fa-home">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="w-100 h-100" xmlns="http://www.w3.org/2000/svg"><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z"></path></svg>
                      </span>{" "}
                      <span className="menu-text">Bet-List</span>
                    </div>
                  </li>

                  <li className={`menu-item admin-sidebar-menu-item ${pathname.includes(MY_ACCOUNT_STATEMENT_URL) ? "active" : ""}`}>
                    <div className="menu-link" onClick={() => handleClick(`/${PORTAL}/${adminData.role}/${MY_ACCOUNT_STATEMENT_URL}`)} >
                      <span className="menu-icon fas fa-home">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="w-100 h-100" xmlns="http://www.w3.org/2000/svg"><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z"></path></svg>
                      </span>{" "}
                      <span className="menu-text">My Account Statement</span>
                    </div>
                  </li>
                  <li className={`menu-item admin-sidebar-menu-item ${pathname.includes(ADMIN_ACCOUNT_STATEMENT_URL) ? "active" : ""}`}>
                    <div className="menu-link" onClick={() => handleClick(`/${PORTAL}/${adminData.role}/${ADMIN_ACCOUNT_STATEMENT_URL}`)} >
                      <span className="menu-icon fas fa-home">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="w-100 h-100" xmlns="http://www.w3.org/2000/svg"><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z"></path></svg>
                      </span>{" "}
                      <span className="menu-text">Admin Account Statement</span>
                    </div>
                  </li>

                  <li className={`menu-item admin-sidebar-menu-item ${pathname.includes(USER_ACCOUNT_STATEMENT_URL) ? "active" : ""}`}>
                    <div className="menu-link" onClick={() => handleClick(`/${PORTAL}/${adminData.role}/${USER_ACCOUNT_STATEMENT_URL}`)} >
                      <span className="menu-icon fas fa-home">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="w-100 h-100" xmlns="http://www.w3.org/2000/svg"><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z"></path></svg>
                      </span>{" "}
                      <span className="menu-text">User Account Statement</span>
                    </div>
                  </li>

                  <li className={`menu-item admin-sidebar-menu-item ${pathname.includes(PROFIT_AND_LOSS_STATEMENT_URL) ? "active" : ""}`}>
                    <div className="menu-link" onClick={() => handleClick(`/${PORTAL}/${adminData.role}/${PROFIT_AND_LOSS_STATEMENT_URL}`)} >
                      <span className="menu-icon fas fa-home">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="w-100 h-100" xmlns="http://www.w3.org/2000/svg"><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z"></path></svg>
                      </span>{" "}
                      <span className="menu-text">P/L Statement</span>
                    </div>
                  </li>

                  <li className={`menu-item admin-sidebar-menu-item ${pathname.includes(GAME_REPORT_URL) ? "active" : ""}`}>
                    <div className="menu-link" onClick={() => handleClick(`/${PORTAL}/${adminData.role}/${GAME_REPORT_URL}`)} >
                      <span className="menu-icon fas fa-home">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="w-100 h-100" xmlns="http://www.w3.org/2000/svg"><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z"></path></svg>
                      </span>{" "}
                      <span className="menu-text">Game Report</span>
                    </div>
                  </li>
                  <li className={`menu-item admin-sidebar-menu-item ${pathname.includes(DISPUTED_BETS_URL) ? "active" : ""}`}>
                    <div className="menu-link" onClick={() => handleClick(`/${PORTAL}/${adminData.role}/${DISPUTED_BETS_URL}`)} >
                      <span className="menu-icon fas fa-home">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="w-100 h-100" xmlns="http://www.w3.org/2000/svg"><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z"></path></svg>
                      </span>{" "}
                      <span className="menu-text">Market Analysis</span>
                    </div>
                  </li>

                  <li className={`menu-item admin-sidebar-menu-item ${pathname.includes(CASINO_REPORT_URL) ? "active" : ""}`}>
                    <div className="menu-link" onClick={() => handleClick(`/${PORTAL}/${adminData.role}/${CASINO_REPORT_URL}`)} >
                      <span className="menu-icon fas fa-home">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="w-100 h-100" xmlns="http://www.w3.org/2000/svg"><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z"></path></svg>
                      </span>{" "}
                      <span className="menu-text">Casino Report</span>
                    </div>
                  </li>

                  {/* <li className={`menu-item admin-sidebar-menu-item ${pathname.includes(ALERT_BETS_URL)?"active":""}`}>
                    <div className="menu-link" onClick={()=>handleClick(`/${PORTAL}/${adminData.role}/${ALERT_BETS_URL}`)} >
                      <span className="menu-icon fas fa-home">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="w-100 h-100" xmlns="http://www.w3.org/2000/svg"><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z"></path></svg>  
                      </span>{" "}
                      <span className="menu-text">Alert Bets</span>
                    </div>
                  </li> */}

                  <li className={`menu-item admin-sidebar-menu-item ${pathname.includes(COMMISSION_REPORT_URL) ? "active" : ""}`}>
                    <div className="menu-link" onClick={() => handleClick(`/${PORTAL}/${adminData.role}/${COMMISSION_REPORT_URL}`)} >
                      <span className="menu-icon fas fa-home">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="w-100 h-100" xmlns="http://www.w3.org/2000/svg"><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z"></path></svg>
                      </span>{" "}
                      <span className="menu-text">Commission Report</span>
                    </div>
                  </li>
                  {/* <li className='menu-item admin-sidebar-menu-item dropdown-box'>
                  <div class="dropdown">
                    <button type="button" class="border-0 dropdown-toggle" data-bs-toggle="dropdown">
                      Admin Payment Details
                    </button>
                    <ul class="dropdown-menu">
                      <li><a class="dropdown-item" href="#">Link 1</a></li>
                      <li><a class="dropdown-item" href="#">Link 2</a></li>
                      <li><a class="dropdown-item" href="#">Link 3</a></li>
                    </ul>
                  </div> */}
                  {/* <div className="menu-link">
                      <span className="menu-icon fas fa-home" />{" "}
                      <span className="menu-text">Admin Payment Details</span>
                    </div> */}
                  {/* </li> */}
                  <li className={`menu-item admin-sidebar-menu-item ${pathname.includes(ADMIN_VIEW_PAYMENTS) ? "active" : ""}`}>
                    <div className="menu-link" onClick={() => handleClick(`/${PORTAL}/${adminData.role}/${ADMIN_VIEW_PAYMENTS}`)} >
                      <span className="menu-icon fas fa-home">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="w-100 h-100" xmlns="http://www.w3.org/2000/svg"><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z"></path></svg>
                      </span>{" "}
                      <span className="menu-text">View Payment</span>
                    </div>
                  </li>


                  <li className="menu-item admin-sidebar-menu-item">
                    <div className="menu-link collapsed" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" >
                      <span className="menu-icon fas fa-home">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="w-100 h-100" xmlns="http://www.w3.org/2000/svg"><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z"></path></svg>
                      </span>{" "}
                      <span className="menu-text">Admin Payment Details</span>
                      <span className="dropDownIcon ms-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 30 30" fill="none">
                          <path d="M5.79492 10.8223L5.90039 10.9453L13.8691 20.1152C14.1387 20.4258 14.543 20.6191 14.9941 20.6191C15.4453 20.6191 15.8496 20.4199 16.1191 20.1152L24.082 10.9629L24.2168 10.8105C24.3164 10.6641 24.375 10.4883 24.375 10.3008C24.375 9.79102 23.9414 9.375 23.4023 9.375H6.59766C6.05859 9.375 5.625 9.79102 5.625 10.3008C5.625 10.4941 5.68945 10.6758 5.79492 10.8223Z" fill="#393939" />
                        </svg>
                      </span>
                    </div>
                  </li>
               
                  <ul class="collapse" id="collapseExample">
                    <li className="menu-item admin-sidebar-menu-item" onClick={() => handleClick(`/${PORTAL}/${adminData.role}/${QR_DETAILS_URL}`)}><div className="menu-link">View Qr Details</div></li>
                    <li className="menu-item admin-sidebar-menu-item" onClick={() => handleClick(`/${PORTAL}/${adminData.role}/${BANK_DETAILS_URL}`)}><div className="menu-link">View Bank Details</div></li>
                    <li className="menu-item admin-sidebar-menu-item" onClick={() => handleClick(`/${PORTAL}/${adminData.role}/${UPI_DETAILS_URL}`)}><div className="menu-link">View UPI Details</div></li>
                    <li className="menu-item admin-sidebar-menu-item" onClick={() => handleClick(`/${PORTAL}/${adminData.role}/${CRYPTO_DETAILS_URL}`)}><div className="menu-link">View Crypto Details</div></li>
                    {/* <li className="menu-item admin-sidebar-menu-item" onClick={() => handleClick(`/${PORTAL}/${adminData.role}/${CRYPTO_VIEW_PAYMENTS}`)}><div className="menu-link">View Payments</div></li> */}
                  </ul>
                  <li className={`menu-item admin-sidebar-menu-item ${pathname.includes(ADMIN_VIEW_BONUS) ? "active" : ""}`}>
                    <div className="menu-link" onClick={() => handleClick(`/${PORTAL}/${adminData.role}/${ADMIN_VIEW_BONUS}`)} >
                      <span className="menu-icon fas fa-home">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="w-100 h-100" xmlns="http://www.w3.org/2000/svg"><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z"></path></svg>
                      </span>{" "}
                      <span className="menu-text">Bonus</span>
                    </div>
                  </li>
                  <li className={`menu-item admin-sidebar-menu-item ${pathname.includes(ADMIN_SETTING) ? "active" : ""}`}>
                    <div className="menu-link" onClick={() => handleClick(`/${PORTAL}/${adminData.role}/${ADMIN_SETTING}`)} >
                      <span className="menu-icon fas fa-home">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="w-100 h-100" xmlns="http://www.w3.org/2000/svg"><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z"></path></svg>
                      </span>{" "}
                      <span className="menu-text">Settings</span>
                    </div>
                  </li>
                </ul>

                {/* /.menu */}
              </nav>
              {/* /.stacked-menu */}
              {/* <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
                <div
                  className="ps__thumb-x"
                  tabIndex={0}
                  style={{ left: 0, width: 0 }}
                />
              </div>
              <div className="ps__rail-y" style={{ top: 0, height: 599, right: 0 }}>
                <div
                  className="ps__thumb-y"
                  tabIndex={0}
                  style={{ top: 0, height: 453 }}
                />
              </div> */}
            </div>
          </div>
        </aside>
        :
        <></>
      }
    </>
  );
};

export default AdminSideBar;